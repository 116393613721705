import React, { useContext, useEffect, useState } from 'react'
import PageHead from '../../Components/PageHead'
import { convertToBase64, getpostalcodes, LogInSchoolID } from '../../JS/Common';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AlertsComp from '../../Components/AlertsComp';
import { CheckValidation } from '../../Components/Validations';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../App';

export default function SchoolProfile() {
    const [NewAlerts, setNewAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [adperror, setAdperror] = useState("");
    const [asperror, setAsperror] = useState("");
    const location = useLocation();
    const { UserData, setUserData } = useContext(UserContext);
    const adminSchoolId = location?.state?.schoolid;
    const fromadminorschool = location?.state?.flag;

    const deviceDistricts = [
        { id: 1, name: "Acer" },
        { id: 2, name: "Apple" },
        { id: 3, name: "Asus" },
        { id: 4, name: "Dell" },
        { id: 5, name: "HP" },
        { id: 6, name: "Lenovo" },
        { id: 7, name: "Samsung" }
    ];
    const repairCoverage = [
        { id: 1, name: "ASP Warranty Repairs" },
        { id: 2, name: "Ad-Hoc Repairs" },
        { id: 3, name: "ADP" },
        { id: 4, name: "Parental Coverage" }
    ];
    const minimumRepairGrade = [
        { id: 1, name: "A" },
        { id: 2, name: "B" },
        { id: 3, name: "C" },
    ];
    const routeDay = [
        { id: 1, name: "Monday" },
        { id: 2, name: "Tuesday" },
        { id: 3, name: "Wednesday" },
        { id: 4, name: "Thursday" },
        { id: 5, name: "Friday" }
    ];
    const routeFrequency = [
        { id: 1, name: "Weekly" },
        { id: 2, name: "Bi-Weekly" },
        { id: 3, name: "Monthly" },
        { id: 4, name: "As-Needed" }
    ];
    const repairPriceTier = [
        { id: 1, name: "Bronze" },
        { id: 2, name: "Silver" },
        { id: 3, name: "Gold" },
    ];
    const cosmeticDamage = [
        { id: 1, name: "Rubber Feet" },
        { id: 2, name: "Hinge Cover" },
        { id: 3, name: "Screw Cover" },
        { id: 4, name: "Stickers" }
    ];
    const exceedingCapHandling = [
        { id: 1, name: "Quote Repair" },
        { id: 2, name: "Quote Replacement" },
        { id: 3, name: "Send Back" },
    ];
    const devicesTakeHome = [
        { id: 1, name: "Summer" },
        { id: 2, name: "Spring" },
        { id: 3, name: "Winter" }
    ];
    const quotingNeeded = [
        { id: 1, name: "Yes" },
        { id: 2, name: "No" },
    ];
    const additionalAssetManagement = [
        { id: 1, name: "Asset Panda" },
        { id: 2, name: "Incident IQ" },
        { id: 3, name: "One-to-One Plus" },
        { id: 4, name: "Self-Developed" },
        { id: 5, name: "Other" }
    ];
    const accountCreditUsage = [
        { id: 1, name: "Accessories" },
        { id: 2, name: "ADP" },
        { id: 3, name: "Buyback" },
        { id: 4, name: "Devices" },
        { id: 5, name: "Repairs" }
    ];
    const uncoveredDevices = [
        { id: 1, name: "Quote" },
        { id: 2, name: "Repair" },
        { id: 3, name: "Send Back" },
        { id: 4, name: "Other" },
    ];

    const [AllState, setallstate] = useState([]);
    const [allTimeZone, setAllTimeZone] = useState([])
    const [SchoolLogo, setSchoolLogo] = useState('');
    const [postImage, setPostImage] = useState({ myFile: "" });
    const [previewLogoDiv, setPreviewLogoDiv] = useState('d-none');
    const [image, setImage] = useState(null);

    const [crop, setCrop] = useState({
        unit: "%",
        width: 30,
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        aspectRatio: 1 / 1,
        minWidth: 0,
        minHeight: 0,
        maxWidth: Infinity,
        maxHeight: Infinity,
    });
    const [result, setResult] = useState(null);
    const [SchoolProfile, setSchoolProfile] = useState({
        accountname: "",
        enrollment: "",
        timezone: "",
        customer: "",
        pricing: 0
        // domain: ""
    });
    const [shippingDetails, setShippingDetails] = useState({
        fedex_company_name: "",
        Phoneno: "",
        Street1: "",
        Street2: "",
        city: "",
        state: "",
        postalcode: ""
    });
    const [billingDetails, setBillingDetails] = useState({
        firstname: "",
        lastname: "",
        phoneno: "",
        email: "",
        accountcredit: "",
        companyname: "",
        street1: "",
        street2: "",
        city: '',
        state: '',
        postalcode: '',
        discoutpricing: '',
        accountcreditusage: []
    });
    const [contactInformation, setContactInformation] = useState({
        email: "",
        firstname: "",
        lastname: "",
        jobtitle: "",
        phonenumber: "",
        phonetype: ""
    });
    const [deviceInformation, setDeviceInformation] = useState({
        weeklyrepairs: "",
        devicesindistrict: []
    });
    const [repairDetails, setRepairDetails] = useState({
        quotingNeeded: "",
        minRepairGrade: "",
        repairPriceTier: "",
        capHandling: "",
        routeDay: '',
        routeFrequency: '',
        cosmeticDamageRepaird: '',
        cosmeticdamageNotes: '',
        selectedDamageRepaird: [],
        deductible: '',
        deductibleNotes: '',
        repairCap: '',
        repairNotes: '',
        repairCoverage: [],
        uncovereddevice: '',
        repairCoverageDetails: []
    });
    const [loanerAsset, setLoanerAsset] = useState({
        deviceTakeHome: [],
        countofLoanerDevice: '',
        additionalAssets: []
    });
    const [k12TechData, setK12TechData] = useState({
        relationShipManager: '',
        locationManager: '',
        driver: '',
        pickupLocation: '',
        buildingname: '',
    });

    const [staffEmails, setStaffEmails] = useState([]);
    const [allTechnicians, setAllTechnicians] = useState([]);
    const [allPickUpLocations, setAllPickUpLocations] = useState([]);
    const [errors, setErrors] = useState({});
    const [ASProws, setASProws] = useState([]);
    const [ADProws, setADProws] = useState([]);
    const [logins, setLogins] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getpostalcodes(setallstate);
        GetAllTimeZone();
    }, []);
    useEffect(() => {
        getSchoolProfileInfo();
    }, [LogInSchoolID, adminSchoolId])

    async function getSchoolProfileInfo() {
        var sId = fromadminorschool == 2 ? adminSchoolId : LogInSchoolID;
        setLoading(true);
        await ApiGetCall("/schoolProfileInformation/" + sId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setSchoolLogo(responseRs?.logo?.logo_path)
                setPostImage({ ...postImage, myFile: responseRs?.logo?.logo_path })
                setSchoolProfile({
                    ...SchoolProfile, accountname: responseRs?.schoolDetails?.name,
                    timezone: responseRs?.schoolShippingAddress?.timezone_id,
                    // domain: responseRs?.schoolProfile?.domain,
                    enrollment: responseRs?.schoolProfile?.enrollment,
                    customer: responseRs?.schoolProfile?.customer,
                    pricing: responseRs?.schoolProfile?.default_pricing
                });
                setShippingDetails({
                    ...shippingDetails, fedex_company_name: responseRs?.schoolShippingAddress?.fedex_company_name,
                    Phoneno: responseRs?.schoolShippingAddress?.phone_num, Street1: responseRs?.schoolShippingAddress?.street_line,
                    Street2: responseRs?.schoolShippingAddress?.street_line2, city: responseRs?.schoolShippingAddress?.city,
                    state: responseRs?.schoolShippingAddress?.state_or_province_code, postalcode: responseRs?.schoolShippingAddress?.postal_code
                });
                setBillingDetails({
                    ...billingDetails, firstname: responseRs?.schoolProfile?.billing_contact?.first_name,
                    lastname: responseRs?.schoolProfile?.billing_contact?.last_name,
                    phoneno: responseRs?.schoolBillingAddress?.phone_num, email: responseRs?.schoolProfile?.billing_contact?.email,
                    companyname: responseRs?.schoolBillingAddress?.fedex_company_name, street1: responseRs?.schoolBillingAddress?.street_line,
                    street2: responseRs?.schoolBillingAddress?.street_line2, city: responseRs?.schoolBillingAddress?.city,
                    state: responseRs?.schoolBillingAddress?.state_or_province_code, postalcode: responseRs?.schoolBillingAddress?.postal_code,
                    accountcredit: responseRs?.schoolProfile?.billing_account_credit,
                    accountcreditusage: responseRs?.schoolProfile ? responseRs?.schoolProfile?.billing_account_credit_usage_array : [],
                    discoutpricing: responseRs?.schoolProfile?.special_discount
                })
                const matchingStaff = responseRs?.allStaffEmails.find(staff => staff?.email == responseRs?.admin.email);
                if (responseRs?.schoolProfile?.main_contact) {
                    setContactInformation({
                        ...contactInformation,
                        email: responseRs?.schoolProfile?.main_contact?.email,
                        firstname: responseRs?.schoolProfile?.main_contact?.first_name,
                        lastname: responseRs?.schoolProfile?.main_contact?.last_name,
                        jobtitle: responseRs?.schoolProfile?.main_contact?.job_title,
                        phonenumber: responseRs?.schoolProfile?.main_contact?.phone_number_ext,
                        phonetype: responseRs?.schoolProfile?.main_contact?.phone_type
                    });
                } else {
                    setContactInformation({
                        ...contactInformation,
                        email: matchingStaff?.email,
                        firstname: matchingStaff?.first_name,
                        lastname: matchingStaff?.last_name,
                        jobtitle: matchingStaff?.job_title,
                        phonenumber: matchingStaff?.phone_number_ext,
                        phonetype: matchingStaff?.phone_type
                    });
                }
                setK12TechData({
                    ...k12TechData, relationShipManager: responseRs?.schoolProfile?.relation_manager?.id,
                    locationManager: responseRs?.schoolProfile?.location_manager?.id,
                    driver: responseRs?.schoolProfile?.driver?.id,
                    pickupLocation: responseRs?.schoolProfile?.pick_up_location?.id
                });
                if (responseRs?.schoolProfile?.repair_coverage_details?.length > 0) {
                    const aspRows = responseRs?.schoolProfile?.repair_coverage_details
                        .filter(item => item.type == 1) // Filter ASP data
                        .map(item => ({
                            deviceType: item.device_type || "",
                            coverageType: item.coverage_type || "",
                            startDate: item.start_date || "",
                            endDate: item.end_date || "",
                            id: item.id || 0
                        }));

                    const adpRows = responseRs?.schoolProfile?.repair_coverage_details
                        .filter(item => item.type == 3) // Filter ADP data (all other types)
                        .map(item => ({
                            deviceType: item.device_type || "",
                            startDate: item.start_date || "",
                            endDate: item.end_date || "",
                            id: item.id || 0
                        }));

                    setASProws([...aspRows, { deviceType: "", coverageType: "", startDate: "", endDate: "", id: 0 }]);
                    setADProws([...adpRows, { deviceType: "", startDate: "", endDate: "", id: 0 }]);
                } else {
                    setASProws([{ deviceType: "", coverageType: "", startDate: "", endDate: "", id: 0 }]);
                    setADProws([{ deviceType: "", startDate: "", endDate: "", id: 0 }]);
                }

                setRepairDetails({
                    ...repairDetails,
                    quotingNeeded: responseRs?.schoolProfile?.needs_quoted,
                    minRepairGrade: responseRs?.schoolProfile?.minimum_repair_grade,
                    repairPriceTier: responseRs?.schoolProfile?.repair_price_tier,
                    capHandling: responseRs?.schoolProfile?.exceeding_cap_handling,
                    routeDay: responseRs?.schoolProfile?.route_day,
                    routeFrequency: responseRs?.schoolProfile?.route_frequency,
                    repairCap: responseRs?.schoolProfile?.repair_cap,
                    repairNotes: responseRs?.schoolProfile?.repair_notes,
                    repairCoverage: responseRs?.schoolProfile ? responseRs?.schoolProfile?.repair_coverage_array : [],
                    uncovereddevice: responseRs?.schoolProfile?.uncover_device_repair_actions,
                    cosmeticDamageRepaird: responseRs?.schoolProfile?.cosmetic_damage_repaired,
                    selectedDamageRepaird: responseRs?.schoolProfile?.cosmetic_damage_repaired_options_array,
                    cosmeticdamageNotes: responseRs?.schoolProfile?.cosmetic_damage_notes,
                    deductible: responseRs?.schoolProfile?.deductible,
                    deductibleNotes: responseRs?.schoolProfile?.deductible_notes
                });
                setLoanerAsset({
                    ...loanerAsset, deviceTakeHome: responseRs?.schoolProfile ?
                        responseRs?.schoolProfile?.take_home_options_array : [],
                    countofLoanerDevice: responseRs?.schoolProfile?.loaner_device_ratio,
                    additionalAssets: responseRs?.schoolProfile ?
                        responseRs?.schoolProfile?.additional_asset_management_array : []
                })
                setStaffEmails(responseRs?.allStaffEmails)
                setAllTechnicians(responseRs?.allTechnicians)
                setDeviceInformation({
                    ...deviceInformation, weeklyrepairs: responseRs?.weeklyRepairsCount,
                    devicesindistrict: responseRs?.schoolProfile ? responseRs?.schoolProfile?.devices_in_district_array : []
                })
                setAllPickUpLocations(responseRs?.allBuilding);
                const deviceTypeMap = {
                    1: "Chromebook",
                    2: "Windows",
                    3: "iPad",
                    4: "MacBook"
                };
                const groupedLogins = responseRs?.schoolProfile?.device_credential_detail?.reduce((acc, item) => {
                    const deviceName = deviceTypeMap[item.device_type] || "Unknown";
                    let existingDevice = acc.find(d => d.device === deviceName);

                    if (existingDevice) {
                        existingDevice.credentials.push({ id: item.id, username: item.user_name, password: item.password, flag: item.id });
                    } else {
                        acc.push({
                            id: item.device_type,
                            device: deviceName,
                            credentials: [{ id: item.id, username: item.user_name, password: item.password, flag: item.id }]
                        });
                    }

                    return acc;
                }, []);

                setLogins(responseRs?.schoolProfile?.device_credential_detail?.length > 0 ? groupedLogins : [
                    { id: 0, device: "Chromebook", credentials: [{ id: 0, username: "", password: "", flag: 0 }] }
                ]);
            }
        });
    }
    async function GetAllTimeZone() {
        await ApiGetCall("/GetTimeZones").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setAllTimeZone(responseRs);
            }
        });

    }
    async function handleFileUpload(e) {
        setPostImage({ ...postImage, myFile: '' });
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        if (base64) {
            setPreviewLogoDiv('');
            setSchoolLogo('');
        } else {
            setPreviewLogoDiv('d-none')
        }
        setPostImage({ myFile: base64 });
    };
    async function getCroppedImg() {
        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d", { alpha: true });
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
            setResult(canvas.toDataURL("image/png"));
        } catch (e) {
            alert("Something went wrong");
        }
    }
    const handleDeviceDistrictCheckboxChange = (e, id) => {
        setDeviceInformation((prevState) => {
            const existingUsage = prevState?.devicesindistrict ?? [];
            const isChecked = e.target.checked;
            const idString = String(id); // Convert ID to string
            return {
                ...prevState,
                devicesindistrict: isChecked
                    ? [...existingUsage.map(String), idString] // Convert all existing values to strings
                    : existingUsage.filter((item) => String(item) !== idString) // Ensure filtering works correctly
            };
        });
    };
    const handleCosmeticDamageCheckboxChange = (e, id) => {
        setRepairDetails((prevState) => {
            const existingUsage = prevState?.selectedDamageRepaird ?? [];
            const isChecked = e.target.checked;
            const idString = String(id);

            return {
                ...prevState,
                selectedDamageRepaird: isChecked
                    ? [...existingUsage.map(String), idString] // Add ID if checked
                    : existingUsage.filter((item) => String(item) !== idString) // Remove if unchecked
            };
        });
    };
    const handleRepairCoverageCheckboxChange = (e, id) => {
        setRepairDetails((prevState) => {
            const existingUsage = prevState?.repairCoverage ?? [];
            const isChecked = e.target.checked;
            const idString = String(id);
            return {
                ...prevState,
                repairCoverage: isChecked
                    ? [...existingUsage.map(String), idString] // Add ID if checked
                    : existingUsage.filter((item) => String(item) !== idString) // Remove if unchecked
            };
        });
    };
    const handleDeviceTakeHomeCheckboxChange = (e, id) => {
        setLoanerAsset((prevState) => {
            const existingUsage = prevState?.deviceTakeHome ?? [];
            const isChecked = e.target.checked;
            const idString = String(id);
            return {
                ...prevState,
                deviceTakeHome: isChecked
                    ? [...existingUsage.map(String), idString] // Add ID if checked
                    : existingUsage.filter((item) => String(item) !== idString) // Remove if unchecked
            };
        });
    };
    const handleAdditionalAssetManagementCheckboxChange = (e, id) => {
        setLoanerAsset((prevState) => {
            const existingUsage = prevState?.additionalAssets ?? [];
            const isChecked = e.target.checked;
            const idString = String(id);
            return {
                ...prevState,
                additionalAssets: isChecked
                    ? [...existingUsage.map(String), idString] // Add ID if checked
                    : existingUsage.filter((item) => String(item) !== idString) // Remove if unchecked
            };
        });
    };
    const handleASPRepairCoverageChange = (index, field, value) => {
        const newRows = [...ASProws];
        newRows[index][field] = value;
        setASProws(newRows);
    };
    const handleAddNewASPRows = () => {
        const lastRow = ASProws[ASProws.length - 1];
        if (lastRow.deviceType && lastRow.coverageType && lastRow.startDate && lastRow.endDate) {
            setASProws([...ASProws, { deviceType: "", coverageType: "", startDate: "", endDate: "" }]);
            setAsperror("")
        } else {
            setAsperror("All Fields are required!");
            return
        }
    }
    const handleADPRepairCoverageChange = (index, field, value) => {
        const newRows = [...ADProws];
        newRows[index][field] = value;
        setADProws(newRows);
    };
    const handleAddNewADPRows = () => {
        const lastRow = ADProws[ADProws.length - 1];
        if (lastRow.deviceType && lastRow.startDate && lastRow.endDate) {
            setADProws([...ADProws, { deviceType: "", startDate: "", endDate: "" }]);
            setAdperror("")
        } else {
            setAdperror("All Fields are required!");
            return
        }
    }
    const validateForm = () => {
        let isValid = true;
        let errors = {};

        // Validate checkboxes
        if (fromadminorschool == 2) {
            if (billingDetails?.accountcreditusage?.length == 0) {
                errors.accountcreditError = '*required';
                isValid = false;
            }
            if (deviceInformation?.devicesindistrict?.length === 0) {
                errors.deviceDistrictsError = '*required';
                isValid = false;
            }
            if (repairDetails?.cosmeticDamageRepaird == 1) {
                if (repairDetails?.selectedDamageRepaird?.length === 0) {
                    errors.selectedcosmeticDamageRepairdError = '*required';
                    isValid = false;
                }
            }
        }

        if (loanerAsset?.deviceTakeHome?.length === 0) {
            errors.devicesTakeHomeError = '*required';
            isValid = false;
        }
        if (loanerAsset?.additionalAssets?.length === 0) {
            errors.assetManagementError = '*required';
            isValid = false;
        }

        // Validate radio buttons
        if (repairDetails.capHandling == 0 || repairDetails.capHandling == undefined) {
            errors.capHandlingError = '*required';
            isValid = false;
        }
        if (SchoolProfile.pricing == 0 || SchoolProfile.pricing == undefined) {
            errors.pricingError = '*required';
            isValid = false;
        }
        if (fromadminorschool == 2) {
            if (repairDetails.minRepairGrade == 0 || repairDetails.minRepairGrade == undefined) {
                errors.minRepairGradeError = '*required';
                isValid = false;
            }
            if (repairDetails.repairPriceTier == 0 || repairDetails.repairPriceTier == undefined) {
                errors.repairPriceTierError = '*required';
                isValid = false;
            }

            if (SchoolProfile.customer == 0 || SchoolProfile.customer == undefined) {
                errors.customerError = '*required';
                isValid = false;
            }
            if (repairDetails.quotingNeeded == 0 || repairDetails.quotingNeeded == undefined) {
                errors.quotingNeededError = '*required';
                isValid = false;
            }
            if (repairDetails.routeDay == 0 || repairDetails.routeDay == undefined) {
                errors.routeDayError = '*required';
                isValid = false;
            }
            if (repairDetails.routeFrequency == 0 || repairDetails.routeFrequency == undefined) {
                errors.routeFrequencyError = '*required';
                isValid = false;
            }
            if (repairDetails.repairCoverage == 0 || repairDetails.repairCoverage == undefined) {
                errors.repairCoverageError = '*required';
                isValid = false;
            }
            if (repairDetails.cosmeticDamageRepaird == 0 || repairDetails.cosmeticDamageRepaird == undefined) {
                errors.cosmeticDamageRepairdError = '*required';
                isValid = false;
            }
            if (repairDetails.deductible == 0 || repairDetails.deductible == undefined) {
                errors.deductibleError = '*required';
                isValid = false;
            }
        }

        if (repairDetails.uncovereddevice == 0 || repairDetails.uncovereddevice == undefined) {
            errors.uncovereddeviceError = '*required';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };
    const handleSaveSchoolProfile = async () => {
        var isFormValid = CheckValidation({ formID: 'SchoolProfileId' });
        if (!validateForm()) return;
        if (!isFormValid) return;
        setLoading(true);
        const shipping = {
            State: shippingDetails.state,
            StreetLine1: shippingDetails.Street1,
            StreetLine2: shippingDetails.Street2 || null,
            PostalCode: shippingDetails.postalcode,
            City: shippingDetails.city,
            CountryCode: "US",
            PhoneNum: shippingDetails.Phoneno,
            FedexCompanyName: shippingDetails.fedex_company_name
        };
        const maincontact = {
            FirstName: contactInformation.firstname,
            LastName: contactInformation.lastname,
            JobTitle: contactInformation.jobtitle,
            PhoneNumberExt: contactInformation.phonenumber,
            PhoneType: contactInformation.phonetype,
            Email: contactInformation.email,
        }
        const pickuplocation = {
            BuildingID: k12TechData.pickupLocation == "other" ? 0 : k12TechData.pickupLocation,
            BuildingName: k12TechData.buildingname
        };
        const billingInfo = {
            FirstName: billingDetails.firstname,
            LastName: billingDetails.lastname,
            JobTitle: billingDetails.jobtitle,
            PhoneNumberExt: billingDetails.phoneno,
            PhoneType: billingDetails.phonetype,
            Email: billingDetails.email,
        };
        const billingaddress = {
            State: billingDetails.state,
            StreetLine1: billingDetails.street1,
            StreetLine2: billingDetails.street2 || null,
            PostalCode: billingDetails.postalcode,
            City: billingDetails.city,
            CountryCode: "US",
            PhoneNum: billingDetails.phoneno,
            CompanyName: billingDetails.companyname
        };
        var repairCoverageArray = [];
        {
            ASProws?.forEach((item) => {
                if (item.deviceType || item.coverageType || item.startDate || item.endDate) {
                    var vjson = {
                        DeviceType: item.deviceType,
                        CoverageType: item.coverageType,
                        StartDate: item.startDate,
                        EndDate: item.endDate,
                        ID: 1,
                        Flag: item.id || 0
                    };
                    repairCoverageArray.push(vjson);
                }
            });
            ADProws?.forEach((item) => {
                if (item.deviceType || item.startDate || item.endDate) {
                    var vjson = {
                        DeviceType: item.deviceType,
                        StartDate: item.startDate,
                        EndDate: item.endDate,
                        ID: 3,
                        Flag: item.id || 0
                    };
                    repairCoverageArray.push(vjson);
                }
            });
        }
        const multiDeviceLogin = logins.map(login => ({
            deviceId: login.id,
            credentials: login.credentials.map(cred => ({
                username: cred.username,
                password: cred.password,
                Flag: cred.flag
            })),
        }));
        const multiDeviceLoginIds = logins.map(login => login.id);
        const raw = JSON.stringify({
            SchoolName: SchoolProfile.accountname,
            Enrollment: SchoolProfile.enrollment,
            TimeZone: SchoolProfile.timezone,
            Customer: SchoolProfile.customer,
            Pricing: SchoolProfile.pricing,
            Logo: postImage.myFile,
            ShippingAddress: shipping,
            WeeklyRepairs: deviceInformation.weeklyrepairs,
            DeviceInDistrict: deviceInformation.devicesindistrict,
            MainContact: maincontact,
            QuotingNeeded: repairDetails.quotingNeeded,
            ExceedingCapHandling: repairDetails.capHandling,
            MinimumRepairGrade: repairDetails.minRepairGrade,
            RepairPriceTier: repairDetails.repairPriceTier,
            RepairCap: repairDetails.repairCap,
            RepairCoverageID: repairDetails.repairCoverage,
            RouteDay: repairDetails.routeDay,
            RouteFrequency: repairDetails.routeFrequency,
            CosmeticDamageRepaired: repairDetails.cosmeticDamageRepaird,
            CosmeticDamageRepairedOption: repairDetails.selectedDamageRepaird,
            CosmeticDamageNotes: repairDetails.cosmeticdamageNotes,
            Deductible: repairDetails.deductible,
            DeductibleNotes: repairDetails.deductibleNotes,
            RepairNotes: repairDetails.repairNotes,
            UncoverDeviceRepairActions: repairDetails.uncovereddevice,
            DevicesTakeHome: loanerAsset.deviceTakeHome,
            AdditionalAssetManagementSystem: loanerAsset.additionalAssets,
            LoanerDeviceRatio: loanerAsset.countofLoanerDevice,
            RelationshipManager: k12TechData.relationShipManager,
            LocationManager: k12TechData.locationManager,
            Driver: k12TechData.driver,
            PickUpLocation: pickuplocation,
            BillingContact: billingInfo,
            SpecialDiscountsPricing: billingDetails.discoutpricing,
            AccountCredit: billingDetails.accountcredit,
            AccountCreditUsage: billingDetails.accountcreditusage,
            BillingAddress: billingaddress,
            SchoolId: fromadminorschool == 2 ? adminSchoolId : LogInSchoolID,
            RepairCoverageArray: repairCoverageArray,
            MultiDeviceLogin: multiDeviceLogin,
            MultiDeviceLoginSelectedArray: multiDeviceLoginIds
        });
        await ApiPostCall("/setSchoolProfileInformation", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"School Profile Added Successfully."} />);
                    if (fromadminorschool != 2) {
                        if (postImage.myFile == "") {
                            setUserData({ ...UserData, schoollogo: "/images/logo-icon.svg" })
                        } else {
                            setUserData({ ...UserData, schoollogo: postImage.myFile })
                        }
                    }
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        if (fromadminorschool == 2) {
                            navigate("/schools");
                        }
                        else {
                            navigate('/manage-device')
                        }
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const addLogin = () => {
        setLogins([...logins, { id: 0, device: "", credentials: [{ id: 0, username: "", password: "", flag: 0 }] }]);
    };

    const updateLogin = (id, field, value) => {
        setLogins(logins.map(login => (login.id == id ? { ...login, [field]: value } : login)));
    };

    const addCredential = (loginId) => {
        setLogins(logins.map(login =>
            login.id == loginId
                ? { ...login, credentials: [...login.credentials, { id: 0, username: "", password: "", flag: 0 }] }
                : login
        ));
    };

    const updateCredential = (loginId, credId, field, value) => {
        setLogins(logins.map(login =>
            login.id == loginId
                ? { ...login, credentials: login.credentials.map(cred => cred.id == credId ? { ...cred, [field]: value } : cred) }
                : login
        ));
    };

    const removeCredential = (loginId, credId) => {
        setLogins(logins.map(login =>
            login.id === loginId
                ? { ...login, credentials: login.credentials.filter(cred => cred.id !== credId) }
                : login
        ));
    };
    const togglePasswordVisibility = (loginId, credId) => {
        setLogins((prevLogins) =>
            prevLogins.map((login) =>
                login.id === loginId
                    ? {
                        ...login,
                        credentials: login.credentials.map((cred) =>
                            cred.id === credId ? { ...cred, showPassword: !cred.showPassword } : cred
                        ),
                    }
                    : login
            )
        );
    };

    return (
        <div>
            <PageHead heading={"School Profile and Pricing"} loading={loading} Alerts={NewAlerts} showsearch={false} />
            <div className="container-fluid px-0 mt-2 parent" id="SchoolProfileId">
                <div className="GridBox p-3">

                    {/* school profile section */}
                    <div className="greyBox p-2 align-items-center" >
                        <div className='row'>
                            <div className='col-12 font-15'>
                                <b>School Profile</b>
                            </div>
                            <div className="col-xl-4 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>School Name / Rocket Account Name<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='accountname'
                                    className="form-control" required value={SchoolProfile.accountname}
                                    onChange={(e) => setSchoolProfile({ ...SchoolProfile, accountname: e.target.value })} />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Set Time Zone<label className='redText'>*</label></div>
                                <select required name='timezone' value={SchoolProfile.timezone} onChange={(e) => setSchoolProfile({ ...SchoolProfile, timezone: e.target.value })}>
                                    <option value="0">Select</option>
                                    {allTimeZone.map((item, i) => {
                                        return <option key={i} value={item.id} defaultChecked={SchoolProfile.timezone == item.id}>{item?.timezone} - {item?.description}</option>
                                    })}
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start pb-1 font-13 shipdevice-font-style'>Pricing<label className='redText'>*</label></div>
                                <div className='col-md-12 font-13 pt-1 ps-3 d-flex justify-content-start align-items-center'>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name='pricing'
                                            checked={SchoolProfile.pricing == 1}
                                            onChange={(e) => setSchoolProfile({ ...SchoolProfile, pricing: 1 })}
                                        />
                                        <label className="form-check-label">Default</label>
                                    </div>
                                    <div className="form-check ms-5">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name='pricing'
                                            checked={SchoolProfile.pricing == 2}
                                            onChange={(e) => setSchoolProfile({ ...SchoolProfile, pricing: 2 })}
                                        />
                                        <label className="form-check-label">Custom</label>
                                    </div>
                                </div>
                                <span className="Emailinvalid" >
                                    {errors.pricingError}
                                </span>
                            </div>

                            {fromadminorschool == 2 && (
                                <div className="col-xl-4 col-12 pt-3 ">
                                    <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Enrollment<label className='redText'>*</label></div>
                                    <input type="text" autoComplete='off' name='enrollment' className="form-control"
                                        required value={SchoolProfile.enrollment}
                                        onChange={(e) => setSchoolProfile({ ...SchoolProfile, enrollment: e.target.value })} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                            )}
                            {fromadminorschool == 2 && (
                                <div className="col-xl-4 col-12 pt-3">
                                    <div className='col-12 text-start pb-1 font-13 shipdevice-font-style'>Customer<label className='redText'>*</label></div>
                                    <div className='col-md-12 font-13 pt-1 ps-3 d-flex justify-content-start align-items-center'>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name='customer'
                                                checked={SchoolProfile.customer == 1}
                                                onChange={(e) => setSchoolProfile({ ...SchoolProfile, customer: 1 })}
                                            />
                                            <label className="form-check-label">Yes</label>
                                        </div>
                                        <div className="form-check ms-5">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name='customer'
                                                checked={SchoolProfile.customer == 2}
                                                onChange={(e) => setSchoolProfile({ ...SchoolProfile, customer: 2 })}
                                            />
                                            <label className="form-check-label">No</label>
                                        </div>
                                    </div>
                                    <span className="Emailinvalid" >
                                        {errors.customerError}
                                    </span>
                                </div>
                            )}
                            {fromadminorschool != 2 && (
                                <div className='row pb-2'>
                                    <div className="col-xl-5 pt-3">
                                        <div className='col-12 text-start px-1 font-13 shipdevice-font-style'>Upload Logo</div>
                                        <form className="col-12">
                                            <input type="file" label="Image" name="myFile" accept=".jpg,.png,.svg,.jpeg" id="UploadFileId" onChange={(e) => handleFileUpload(e)} />
                                            <label className="ImportInventoryBtn col-md-9 col-9" htmlFor="UploadFileId">Upload logo</label>
                                            <label className="ImportInventoryBtn1 col-md-3 col-3 text-center" htmlFor="UploadFileId" style={{ color: "white" }}>
                                                Browse
                                            </label>
                                        </form>
                                    </div>
                                    <div className="col-7 pt-3 font-13">
                                        {SchoolLogo ? (
                                            <div className="col-12 text-center">
                                                <img src={SchoolLogo} alt="school logo" className='img-fluid' style={{ height: "100px", width: "100px" }} />
                                                <img
                                                    src="/images/CancelIcon.svg"
                                                    title="Remove Logo"
                                                    className="img-fluid cursor-pointer ps-3"
                                                    onClick={(e) => { setPostImage({ ...postImage, myFile: "" }); setSchoolLogo("") }}
                                                />
                                            </div>
                                        ) : (
                                            <div className={`col-md-12 ${previewLogoDiv}`}>
                                                <div className="py-3 uploadLogoDiv">
                                                    {postImage.myFile && (
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <ReactCrop
                                                                    className="cursor-pointer"
                                                                    style={{ width: "100%", height: "100%" }}
                                                                    src={postImage.myFile}
                                                                    onImageLoaded={setImage}
                                                                    crop={crop}
                                                                    onChange={setCrop}
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                {result && <img src={result} alt="cropped image" />}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <input
                                                    type="submit"
                                                    value="Preview"
                                                    className="SaveBtn upload-save px-3"
                                                    onClick={getCroppedImg}
                                                    style={{ float: "right" }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>


                    {/* shipping details section */}
                    <div className="greyBox  p-2 my-3 align-items-center" >
                        <div className='row pb-2'>
                            <div className='col-12 pb-2 font-15'>
                                <b>Shipping Details</b>
                            </div>
                            <div className="col-xl-8 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>
                                    Fedex Shipping Company name<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='fedex_company_name'
                                    className="form-control" required value={shippingDetails.fedex_company_name}
                                    onChange={(e) => setShippingDetails({ ...shippingDetails, fedex_company_name: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Phone No.<label className='redText'>*</label></div>
                                <input type="number" autoComplete='off' name='Phoneno' className="form-control" required
                                    value={shippingDetails.Phoneno} onChange={(e) => setShippingDetails({ ...shippingDetails, Phoneno: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Street Line 1<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='Street1' className="form-control" required
                                    value={shippingDetails.Street1} onChange={(e) => setShippingDetails({ ...shippingDetails, Street1: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Street Line 2</div>
                                <input type="text" autoComplete='off' name='Street2' className="form-control"
                                    value={shippingDetails.Street2} onChange={(e) => setShippingDetails({ ...shippingDetails, Street2: e.target.value })}
                                />
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>City<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='city' className="form-control" required
                                    value={shippingDetails.city} onChange={(e) => setShippingDetails({ ...shippingDetails, city: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>State<label className='redText'>*</label></div>
                                <select required name='state' value={shippingDetails.state} onChange={(e) => setShippingDetails({ ...shippingDetails, state: e.target.value })}>
                                    <option value="0">Select</option>
                                    {AllState.map((item, i) => {
                                        return <option key={i} value={item.state_code}>{item.state_code}</option>
                                    })}
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Postal Code<label className='redText'>*</label></div>
                                <input type="number" required autoComplete='off' name='postalcode' className="form-control"
                                    value={shippingDetails.postalcode} onChange={(e) => setShippingDetails({ ...shippingDetails, postalcode: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Country</div>
                                <input type="text" autoComplete='off' value='US' disabled name='City' className="form-control" />
                            </div>
                        </div>
                    </div>


                    {/* billing information section */}
                    <div className="greyBox p-2 my-3 align-items-center" >
                        <div className='row pb-2'>
                            <div className='col-12 font-15'>
                                <b>Billing Details</b>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>First Name<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='billingfirstname'
                                    className="form-control" required value={billingDetails.firstname}
                                    onChange={(e) => setBillingDetails({ ...billingDetails, firstname: e.target.value })} />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Last Name<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='billinglastname' className="form-control" required
                                    value={billingDetails.lastname} onChange={(e) => setBillingDetails({ ...billingDetails, lastname: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Phone No.<label className='redText'>*</label></div>
                                <input type="number" autoComplete='off' name='billingphoneno' className="form-control" required
                                    value={billingDetails.phoneno} onChange={(e) => setBillingDetails({ ...billingDetails, phoneno: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Email<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='billingemail' className="form-control" required
                                    value={billingDetails.email} onChange={(e) => setBillingDetails({ ...billingDetails, email: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>

                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Company Name<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='billingcompanyname' className="form-control" required
                                    value={billingDetails.companyname} onChange={(e) => setBillingDetails({ ...billingDetails, companyname: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Street Line 1<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='billingstreet1' className="form-control" required
                                    value={billingDetails.street1} onChange={(e) => setBillingDetails({ ...billingDetails, street1: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Street Line 2</div>
                                <input type="text" autoComplete='off' name='billingstreet2' className="form-control"
                                    value={billingDetails.street2} onChange={(e) => setBillingDetails({ ...billingDetails, street2: e.target.value })}
                                />
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>City<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='billingcity' className="form-control" required
                                    value={billingDetails.city} onChange={(e) => setBillingDetails({ ...billingDetails, city: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>State*</div>
                                <select required name='billingstate' value={billingDetails.state} onChange={(e) => setBillingDetails({ ...billingDetails, state: e.target.value })}>
                                    <option value="0">Select</option>
                                    {AllState.map((item, i) => {
                                        return <option key={i} value={item.state_code} >{item.state_code}</option>
                                    })}
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Postal Code<label className='redText'>*</label></div>
                                <input type="number" autoComplete='off' name='billingpostalcode' className="form-control" required
                                    value={billingDetails.postalcode} onChange={(e) => setBillingDetails({ ...billingDetails, postalcode: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Country</div>
                                <input type="text" autoComplete='off' value='US' disabled name='City' className="form-control" />
                            </div>
                            {fromadminorschool == 2 &&
                                <div className='col-md-3 col-12 pt-3'>
                                    <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Account Credit<label className='redText'>*</label></div>
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input type="text" required autoComplete='off' className="form-control"
                                            name='accountcredit'
                                            placeholder='00' pattern="[0-9]+(\.[0-9]+)?"
                                            value={billingDetails.accountcredit} onChange={(e) => setBillingDetails({ ...billingDetails, accountcredit: e.target.value })}
                                        />
                                        <span className="form-text invalid-feedback">  *required</span>
                                    </div>
                                </div>
                            }
                            {fromadminorschool == 2 &&
                                <div className="col-xl-6 col-12 pt-4 ">
                                    <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Special Discounts or Pricing<label className='redText'>*</label></div>
                                    <textarea rows={2} autoComplete='off' name='billingspecialdiscount' className="form-control" required
                                        value={billingDetails.discoutpricing} onChange={(e) => setBillingDetails({ ...billingDetails, discoutpricing: e.target.value })}
                                    />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                            }
                            {fromadminorschool == 2 &&
                                <div className='col-xl-6 col-12 pt-4 row'>
                                    <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Account Credit Usage<label className='redText'>*</label></div>
                                    {accountCreditUsage.map((account, index) => (
                                        <div className="col-md-4 font-13 d-flex align-items-center" key={index}>
                                            <input
                                                id={`accountcredit-${account.id}`}
                                                className="form-check-input mt-1 me-2 cursor-pointer"
                                                type="checkbox"
                                                aria-label={account.id}
                                                checked={billingDetails?.accountcreditusage?.includes(String(account.id)) || false}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    const idString = String(account.id);
                                                    setBillingDetails((prevState) => {
                                                        const existingUsage = prevState?.accountcreditusage ?? [];
                                                        return {
                                                            ...prevState,
                                                            accountcreditusage: isChecked
                                                                ? [...existingUsage.map(String), idString] // Add ID if checked
                                                                : existingUsage.filter((item) => String(item) !== idString) // Remove if unchecked
                                                        };
                                                    });
                                                }}
                                            />
                                            <label htmlFor={`accountcredit-${account.id}`} className="form-check-label">
                                                {account.name}
                                            </label>
                                        </div>
                                    ))}
                                    <span className="Emailinvalid" >
                                        {errors.accountcreditError}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>


                    {/* main contact information section */}
                    <div className="greyBox  p-2 my-3 align-items-center" >
                        <div className='row pb-2'>
                            <div className='col-12 font-15'>
                                <b>Main Contact Details</b>
                            </div>
                            <div className="col-xl-4 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Email<label className='redText'>*</label></div>
                                <select required value={contactInformation.email} name='email'
                                    onChange={(e) => {
                                        const selectedEmail = e.target.value;
                                        const selectedStaff = staffEmails.find((item) => item.email == selectedEmail);

                                        setContactInformation({
                                            ...contactInformation,
                                            email: selectedEmail,
                                            firstname: selectedStaff?.first_name || "",
                                            lastname: selectedStaff?.last_name || ""
                                        });
                                    }}>
                                    <option value="0">Select</option>
                                    {staffEmails?.map((item, i) => {
                                        return <option key={i} value={item.email} defaultChecked={contactInformation.email}>{item.email}</option>
                                    })
                                    }
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>First Name</div>
                                <input type="text" disabled autoComplete='off' value={contactInformation.firstname} name='firstname' className="form-control" />
                            </div>
                            <div className="col-xl-4 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Last Name</div>
                                <input type="text" disabled autoComplete='off' value={contactInformation.lastname} name='lastname' className="form-control" />
                            </div>
                            <div className="col-xl-4 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Job Title<label className='redText'>*</label></div>
                                <input type="text" autoComplete='off' name='jobtitle' className="form-control" required
                                    value={contactInformation.jobtitle} onChange={(e) => setContactInformation({ ...contactInformation, jobtitle: e.target.value })} />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Phone Number & Ext<label className='redText'>*</label></div>
                                <input type="number" autoComplete='off' name='phonenumber' className="form-control" required
                                    value={contactInformation.phonenumber} onChange={(e) => setContactInformation({ ...contactInformation, phonenumber: e.target.value })} />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-4 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Phone Type<label className='redText'>*</label></div>
                                <select required name='phonetype' value={contactInformation.phonetype} onChange={(e) => setContactInformation({ ...contactInformation, phonetype: e.target.value })}>
                                    <option value="0">Select</option>
                                    <option value="1">Work</option>
                                    <option value="2">Mobile</option>
                                    <option value="3">Other</option>
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                        </div>
                    </div>


                    {/* k12 tech details section */}
                    <div className="greyBox p-2 my-3 align-items-center" >
                        <div className='row pb-2'>
                            <div className='col-12 font-15'>
                                <b>K-12 Tech Details</b>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Relationship Manager<label className='redText'>*</label></div>
                                <select required name='relationshipmanager' style={{ backgroundColor: fromadminorschool != 2 && "#e9ecef" }} disabled={fromadminorschool != 2} value={k12TechData.relationShipManager} onChange={(e) => setK12TechData({ ...k12TechData, relationShipManager: e.target.value })}>
                                    <option value="0">Select</option>
                                    {allTechnicians.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.first_name} {item.last_name}</option>
                                    })}
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Location Manager<label className='redText'>*</label></div>
                                <select required name='locationmanager'
                                    style={{ backgroundColor: fromadminorschool != 2 && "#e9ecef" }}
                                    disabled={fromadminorschool != 2} value={k12TechData.locationManager}
                                    onChange={(e) => setK12TechData({ ...k12TechData, locationManager: e.target.value })}>
                                    <option value="0">Select</option>
                                    {allTechnicians.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.first_name} {item.last_name}</option>
                                    })}
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Driver<label className='redText'>*</label></div>
                                <select required name='driver' style={{ backgroundColor: fromadminorschool != 2 && "#e9ecef" }} disabled={fromadminorschool != 2} value={k12TechData.driver} onChange={(e) => setK12TechData({ ...k12TechData, driver: e.target.value })}>
                                    <option value="0">Select</option>
                                    {allTechnicians.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.first_name} {item.last_name}</option>
                                    })}
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Pick-Up Location<label className='redText'>*</label></div>
                                <select required name='pickuplocation' value={k12TechData.pickupLocation} onChange={(e) => setK12TechData({ ...k12TechData, pickupLocation: e.target.value })}>
                                    <option value="0">Select</option>
                                    {allPickUpLocations.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>
                                    })}
                                    <option value="other">Other</option>
                                </select>
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            {k12TechData.pickupLocation == "other" && (
                                <div className="col-xl-3 offset-xl-9 col-12 pt-3 ">
                                    <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Enter Building<label className='redText'>*</label></div>
                                    <input type="text" autoComplete='off' name='buildingname' className="form-control" required
                                        value={k12TechData.buildingname} onChange={(e) => setK12TechData({ ...k12TechData, buildingname: e.target.value })} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>


                    {/* device information section*/}
                    <div className="greyBox  p-2 my-3 align-items-center" >
                        <div className='row pb-2'>
                            <div className='col-12 font-15'>
                                <b>Device Details</b>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 font-13 pb-1 shipdevice-font-style'>Weekly Repairs</div>
                                <input type="text" autoComplete='off' name='weeklyrepairs' className="form-control"
                                    value={deviceInformation.weeklyrepairs} disabled />
                            </div>
                            {fromadminorschool == 2 &&
                                <div className='col-xl-9 col-12 pt-3 '>
                                    <div className='text-start pt-1 px-1 font-13 pb-1 shipdevice-font-style'>Devices in District</div>
                                    <div className='d-flex pt-1 justify-content-between'>
                                        {deviceDistricts.map((brand, index) => (
                                            <div className="font-13 pb-1 d-flex align-items-center" key={index}>
                                                <input
                                                    id={`device-${brand.id}`}
                                                    className="form-check-input mt-0 me-2 cursor-pointer"
                                                    type="checkbox"
                                                    checked={deviceInformation?.devicesindistrict?.includes(String(brand.id)) || false}
                                                    onChange={(e) => handleDeviceDistrictCheckboxChange(e, brand.id)}
                                                />
                                                <label htmlFor={`device-${brand.id}`} className="form-check-label">
                                                    {brand.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <span className="Emailinvalid" >
                                        {errors.deviceDistrictsError}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>


                    {/* repair details section */}
                    <div className="greyBox  p-2 my-3 align-items-center" >
                        <div className='row pb-2'>
                            <div className='col-12 font-15'>
                                <b>Repair Details</b>
                            </div>
                            {fromadminorschool == 2 &&
                                <div className="col-xl-4 col-12 pt-3 ">
                                    <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2 h-100'>
                                        <div className='col-12 text-start pb-1 shipdevice-font-style'>Quoting Needed?<label className='redText'>*</label></div>
                                        <div className='col-md-12 font-13 pt-2 ps-3'>
                                            {quotingNeeded.map((quote, index) => (
                                                <div className="font-13 ps-3 d-flex align-items-center" key={index}>
                                                    <input
                                                        id={`quotingNeeded-${quote.id}`}
                                                        className="form-check-input me-2 cursor-pointer"
                                                        type="radio"
                                                        aria-label={quote.id}
                                                        name='quoting'
                                                        checked={repairDetails.quotingNeeded == quote.id}
                                                        onChange={(e) => setRepairDetails({ ...repairDetails, quotingNeeded: quote.id })}
                                                    />
                                                    <label htmlFor={`quotingNeeded-${quote.id}`} className="form-check-label">
                                                        {quote.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <span className="Emailinvalid" >
                                            {errors.quotingNeededError}
                                        </span>
                                    </div>
                                </div>
                            }
                            {fromadminorschool == 2 &&
                                <div className="col-xl-4 col-12 pt-3 ">
                                    <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2 h-100'>
                                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Minimum Repair Grade<label className='redText'>*</label></div>
                                        <div className='col-md-12 font-13 ps-3 '>
                                            {minimumRepairGrade.map((repairgrade, index) => (
                                                <div className="font-13 ps-3 d-flex align-items-center" key={index}>
                                                    <input
                                                        id={`repairgrade-${repairgrade.id}`}
                                                        className="form-check-input me-2 cursor-pointer"
                                                        type="radio"
                                                        aria-label={repairgrade.id}
                                                        name='repairgrade'
                                                        checked={repairDetails.minRepairGrade == repairgrade.id}
                                                        onChange={(e) => setRepairDetails({ ...repairDetails, minRepairGrade: repairgrade.id })}
                                                    />
                                                    <label htmlFor={`repairgrade-${repairgrade.id}`} className="form-check-label">
                                                        {repairgrade.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <span className="Emailinvalid" >
                                            {errors.minRepairGradeError}
                                        </span>
                                    </div>
                                </div>
                            }
                            {fromadminorschool == 2 &&
                                <div className="col-xl-4 col-12 pt-3 ">
                                    <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2 h-100'>
                                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Repair Price Tier<label className='redText'>*</label></div>
                                        <div className='col-md-12 font-13 ps-3 '>
                                            {repairPriceTier.map((repairprice, index) => (
                                                <div className="font-13 ps-3 d-flex align-items-center" key={index}>
                                                    <input
                                                        id={`repairpricetier-${repairprice.id}`}
                                                        className="form-check-input me-2 cursor-pointer"
                                                        type="radio"
                                                        aria-label={repairprice.id}
                                                        name='repairpricetier'
                                                        checked={repairDetails.repairPriceTier == repairprice.id}
                                                        onChange={(e) => setRepairDetails({ ...repairDetails, repairPriceTier: repairprice.id })}
                                                    />
                                                    <label htmlFor={`repairpricetier-${repairprice.id}`} className="form-check-label">
                                                        {repairprice.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <span className="Emailinvalid" >
                                            {errors.repairPriceTierError}
                                        </span>
                                    </div>
                                </div>
                            }
                            <div className="col-xl-4 col-12 pt-3">
                                <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2 h-100'>
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Exceeding Cap Handling<label className='redText'>*</label></div>
                                    <div className='col-md-12 font-13 ps-3'>
                                        {exceedingCapHandling.map((caphandling, index) => (
                                            <div className="font-13 ps-3 d-flex align-items-center" key={index}>
                                                <input
                                                    id={`caphandling-${caphandling.id}`}
                                                    className="form-check-input me-2 cursor-pointer"
                                                    type="radio"
                                                    aria-label={caphandling.id}
                                                    name='caphandling'
                                                    checked={repairDetails.capHandling == caphandling.id}
                                                    onChange={(e) => setRepairDetails({ ...repairDetails, capHandling: caphandling.id })}
                                                />
                                                <label htmlFor={`caphandling-${caphandling.id}`} className="form-check-label">
                                                    {caphandling.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <span className="Emailinvalid" >
                                        {errors.capHandlingError}
                                    </span>
                                </div>
                            </div>
                            <div className='col-xl-4 col-12 pt-3'>
                                <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2 h-100'>
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Route Day<label className='redText'>*</label></div>
                                    {routeDay.map((day, index) => (
                                        <div className="font-13 d-flex ps-3 align-items-center" key={index}>
                                            <input
                                                id={`routeday-${day.id}`}
                                                className="form-check-input mt-1 me-2 cursor-pointer"
                                                type="radio"
                                                aria-label={day.id}
                                                name="routeday"
                                                checked={repairDetails.routeDay == day.id}
                                                disabled={fromadminorschool != 2}
                                                onChange={(e) => setRepairDetails({ ...repairDetails, routeDay: day.id })}
                                            />
                                            <label htmlFor={`routeday-${day.id}`} className="form-check-label">
                                                {day.name}
                                            </label>
                                        </div>
                                    ))}
                                    <span className="Emailinvalid" >
                                        {errors.routeDayError}
                                    </span>
                                </div>

                            </div>
                            <div className='col-xl-4 col-12 pt-3'>
                                <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2 h-100'>
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Route Frequency<label className='redText'>*</label></div>
                                    {routeFrequency.map((route, index) => (
                                        <div className="font-13 ps-3 d-flex align-items-center" key={index}>
                                            <input
                                                id={`routefrequency-${route.id}`}
                                                className="form-check-input me-2 cursor-pointer"
                                                type="radio"
                                                aria-label={route.id}
                                                name='routefrequency'
                                                checked={repairDetails.routeFrequency == route.id}
                                                disabled={fromadminorschool != 2}
                                                onChange={(e) => setRepairDetails({ ...repairDetails, routeFrequency: route.id })}
                                            />
                                            <label htmlFor={`routefrequency-${route.id}`} className="form-check-label">
                                                {route.name}
                                            </label>
                                        </div>
                                    ))}
                                    <span className="Emailinvalid" >
                                        {errors.routeFrequencyError}
                                    </span>
                                </div>

                            </div>
                            {fromadminorschool == 2 &&
                                <div className='col-12 pt-3'>
                                    <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2'>
                                        <div className='row'>
                                            <div className="col-xl-4 col-12">
                                                <div className='col-12 text-start pb-1 shipdevice-font-style'>Cosmetic Damage Repaired?<label className='redText'>*</label></div>
                                                <div className='col-md-12 font-13 pt-2 ps-3 d-flex align-items-center'>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name='cosmeticdamage'
                                                            checked={repairDetails.cosmeticDamageRepaird == 1}
                                                            onChange={(e) => setRepairDetails({ ...repairDetails, cosmeticDamageRepaird: 1 })}
                                                        />
                                                        <label className="form-check-label">Yes</label>
                                                    </div>
                                                    <div className="form-check ms-5">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name='cosmeticdamage'
                                                            checked={repairDetails.cosmeticDamageRepaird == 2}
                                                            onChange={(e) => setRepairDetails({ ...repairDetails, cosmeticDamageRepaird: 2 })}
                                                        />
                                                        <label className="form-check-label">No</label>
                                                    </div>
                                                </div>
                                                <span className="Emailinvalid" >
                                                    {errors.cosmeticDamageRepairdError}
                                                </span>
                                            </div>

                                            {repairDetails.cosmeticDamageRepaird == 1 &&
                                                <div className='col-xl-4 col-12 row'>
                                                    <div className='col-12 text-start px-1 shipdevice-font-style'>Select Damage Repaired<label className='redText'>*</label></div>
                                                    {cosmeticDamage.map((cosmetic, index) => (
                                                        <div className="col-md-6 font-13 d-flex align-items-center" key={index}>
                                                            <input
                                                                id={`cosmeticdamage-${cosmetic.id}`}
                                                                className="form-check-input mt-1 me-2 cursor-pointer"
                                                                type="checkbox"
                                                                aria-label={cosmetic.id}
                                                                checked={repairDetails?.selectedDamageRepaird?.includes(String(cosmetic.id)) || false}
                                                                onChange={(e) => handleCosmeticDamageCheckboxChange(e, cosmetic.id)}
                                                            />
                                                            <label htmlFor={`cosmeticdamage-${cosmetic.id}`} className="form-check-label">
                                                                {cosmetic.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                    <span className="Emailinvalid" >
                                                        {errors.selectedcosmeticDamageRepairdError}
                                                    </span>
                                                </div>

                                            }
                                            <div className="col-xl-4 col-12 ">
                                                <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Cosmetic Damage Notes<label className='redText'>*</label></div>
                                                <textarea rows={2} autoComplete='off' name='cosmeticdamagenotes' className="form-control" required
                                                    value={repairDetails.cosmeticdamageNotes} onChange={(e) => setRepairDetails({ ...repairDetails, cosmeticdamageNotes: e.target.value })}
                                                />
                                                <span className="form-text invalid-feedback" >
                                                    *required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {fromadminorschool == 2 &&
                                <>
                                    <div className="col-xl-3 col-12 pt-3">
                                        <div className='col-12 text-start pb-1 shipdevice-font-style'>Deductible?<label className='redText'>*</label></div>
                                        <div className='col-md-12 font-13 pt-2 ps-3 d-flex align-items-center'>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name='deductible'
                                                    checked={repairDetails.deductible == 1}
                                                    onChange={(e) => setRepairDetails({ ...repairDetails, deductible: 1 })}
                                                />
                                                <label className="form-check-label">Yes</label>
                                            </div>
                                            <div className="form-check ms-5">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name='deductible'
                                                    checked={repairDetails.deductible == 2}
                                                    onChange={(e) => setRepairDetails({ ...repairDetails, deductible: 2 })}
                                                />
                                                <label className="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <span className="Emailinvalid" >
                                            {errors.deductibleError}
                                        </span>
                                    </div>
                                    <div className="col-xl-3 col-12 pt-3">
                                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Deductible Notes<label className='redText'>*</label></div>
                                        <textarea rows={1} autoComplete='off' name='deductiblenotes' className="form-control" required
                                            value={repairDetails.deductibleNotes} onChange={(e) => setRepairDetails({ ...repairDetails, deductibleNotes: e.target.value })}
                                        />
                                        <span className="form-text invalid-feedback" >
                                            *required
                                        </span>
                                    </div>
                                </>
                            }
                            <div className='col-xl-3 col-12 pt-3'>
                                <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Repair Cap<label className='redText'>*</label></div>
                                <div className="input-group ">
                                    <span className="input-group-text">$</span>
                                    <input type="number" required autoComplete='off' className="form-control"
                                        name='repaircap'
                                        placeholder='00' pattern="[0-9]+(\.[0-9]+)?"
                                        value={repairDetails.repairCap} onChange={(e) => setRepairDetails({ ...repairDetails, repairCap: e.target.value })}
                                    />
                                    <span className="form-text invalid-feedback">  *required</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-12 pt-3 ">
                                <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Repair Notes<label className='redText'>*</label></div>
                                {fromadminorschool == 2 ?
                                    <textarea rows={1} autoComplete='off' name='repairnotes' className="form-control" required
                                        value={repairDetails.repairNotes} onChange={(e) => setRepairDetails({ ...repairDetails, repairNotes: e.target.value })}
                                    />
                                    :
                                    <textarea rows={1} autoComplete='off' name='repairnotes' className="form-control" required
                                        value={repairDetails.repairNotes} disabled
                                    />
                                }
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className='col-md-12 pt-3'>
                                <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2'>
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Multi Device Login<label className='redText'>*</label></div>
                                    {logins.map((login, index) => (
                                        <div className='row py-2' key={index}>
                                            <div className='col-md-4'>
                                                <div className='col-md-12'>
                                                    <select required value={login.id} onChange={(e) => updateLogin(login.id, "id", parseInt(e.target.value))}>
                                                        <option value="0">Select Device</option>
                                                        <option value="1">Chromebook</option>
                                                        <option value="2">Windows</option>
                                                        <option value="3">iPad</option>
                                                        <option value="4">MacBook</option>
                                                    </select>
                                                    <span className="form-text invalid-feedback" >
                                                        *required
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col-md-8'>
                                                {login.credentials.map((cred) => (
                                                    <div key={cred.id} className="row pb-1 align-items-center">
                                                        <div className='col-md-5 offset-md-1'>
                                                            <input className='form-control' required placeholder="Username" value={cred.username} onChange={(e) => updateCredential(login.id, cred.id, "username", e.target.value)} />
                                                            <span className="form-text invalid-feedback" >
                                                                *required
                                                            </span>
                                                        </div>
                                                        <div className='col-md-5 position-relative'>
                                                            <input className='form-control' required type={cred.showPassword ? "text" : "password"} placeholder="Password" value={cred.password} onChange={(e) => updateCredential(login.id, cred.id, "password", e.target.value)} />
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm position-absolute top-50 translate-middle-y"
                                                                style={{ right: "5%" }}
                                                                onClick={() => togglePasswordVisibility(login.id, cred.id)}
                                                            >
                                                                {cred.showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                                            </button>
                                                            <span className="form-text invalid-feedback" >
                                                                *required
                                                            </span>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            {cred.id == 0 &&
                                                                login.credentials.length > 1 && (
                                                                    <img src="/images/CancelIcon.svg" title='Remove' onClick={() => removeCredential(login.id, cred.id)} />
                                                                )}
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='col-12 text-center'>
                                                    <button onClick={() => addCredential(login.id)} className="col-md-4 mt-2 BorderBtn ">
                                                        Add Another Login
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='col-12 pt-3'>
                                        <label onClick={addLogin} className="text-decoration-underline cursor-pointer">
                                            Add New Device
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 pt-3'>
                                <div style={{ border: "1px solid #e2e3e4", borderRadius: "0.3rem" }} className='p-2'>
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Repair Coverage<label className='redText'>*</label></div>
                                    <div className='row'>
                                        {repairCoverage.map((repairs, index) => (
                                            <div key={index} className="col-12 font-13 d-flex flex-column">
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        id={`repairs-${repairs.id}`}
                                                        className="form-check-input mt-1 me-2 cursor-pointer"
                                                        type="checkbox"
                                                        aria-label={repairs.id}
                                                        checked={repairDetails?.repairCoverage?.includes(String(repairs.id)) || false}
                                                        disabled={fromadminorschool != 2}
                                                        onChange={(e) => handleRepairCoverageCheckboxChange(e, repairs.id)}
                                                    />
                                                    <label htmlFor={`repairs-${repairs.id}`} className="form-check-label">
                                                        {repairs.name}
                                                    </label>
                                                </div>
                                                {repairs.id == 1 && repairDetails?.repairCoverage?.includes(String(repairs.id)) && (
                                                    <div className='p-3'>
                                                        <div className=' row'>
                                                            <div className='col-md-3 text-start shipdevice-font-style'>Device Type</div>
                                                            <div className='col-md-3 text-start shipdevice-font-style'>Coverage Start Date</div>
                                                            <div className='col-md-3 text-start shipdevice-font-style'>Coverage End Date</div>
                                                            <div className='col-md-3 text-start shipdevice-font-style'>Coverage Type</div>
                                                        </div>
                                                        <>
                                                            {ASProws.map((row, index) => (
                                                                <div className='pt-2 row' key={index}>
                                                                    <div className='col-md-3'>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={row.deviceType}
                                                                            onChange={(e) => handleASPRepairCoverageChange(index, "deviceType", e.target.value)}
                                                                            placeholder="Enter device type"
                                                                            disabled={fromadminorschool != 2}

                                                                        />
                                                                    </div>

                                                                    <div className='col-md-3'>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            value={row.startDate}
                                                                            onChange={(e) => handleASPRepairCoverageChange(index, "startDate", e.target.value)}
                                                                            disabled={fromadminorschool != 2}

                                                                        />

                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            value={row.endDate}
                                                                            onChange={(e) => handleASPRepairCoverageChange(index, "endDate", e.target.value)}
                                                                            list="coverageEndDates"
                                                                            disabled={fromadminorschool != 2}

                                                                        />

                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <select disabled={fromadminorschool != 2}
                                                                            style={{ backgroundColor: fromadminorschool != 2 && "#e9ecef" }}
                                                                            value={row.coverageType}
                                                                            onChange={(e) => handleASPRepairCoverageChange(index, "coverageType", e.target.value)}>
                                                                            <option value="0">Select Coverage</option>
                                                                            <option value="CLW">CLW</option>
                                                                            <option value="ADP">ADP</option>
                                                                        </select>

                                                                    </div>
                                                                    <div className='redText'> {asperror}</div>

                                                                </div>
                                                            ))}
                                                        </>
                                                        <div className='col-12 text-center pt-2'>
                                                            <button className='SaveBtn align-items-center' onClick={handleAddNewASPRows}>
                                                                <img src='/images/AddMoreIcon.svg' className='img-fluid px-2' />
                                                                Add More
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {repairs.id == 3 && repairDetails?.repairCoverage?.includes(String(repairs.id)) && (
                                                    <div className='p-3'>
                                                        <div className=' row'>
                                                            <div className='col-md-3 text-start shipdevice-font-style'>Device Type</div>
                                                            <div className='col-md-3 text-start shipdevice-font-style'>Coverage Start Date</div>
                                                            <div className='col-md-3 text-start shipdevice-font-style'>Coverage End Date</div>
                                                        </div>
                                                        <>
                                                            {ADProws.map((row, index) => (
                                                                <div className='pt-2 row' key={index}>
                                                                    <div className='col-md-3'>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={row.deviceType}
                                                                            onChange={(e) => handleADPRepairCoverageChange(index, "deviceType", e.target.value)}
                                                                            placeholder="Enter device type"
                                                                            disabled={fromadminorschool != 2}
                                                                        />
                                                                    </div>

                                                                    <div className='col-md-3'>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            value={row.startDate}
                                                                            onChange={(e) => handleADPRepairCoverageChange(index, "startDate", e.target.value)}
                                                                            disabled={fromadminorschool != 2}
                                                                        />

                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            value={row.endDate}
                                                                            onChange={(e) => handleADPRepairCoverageChange(index, "endDate", e.target.value)}
                                                                            list="coverageEndDates"
                                                                            disabled={fromadminorschool != 2}
                                                                        />

                                                                    </div>
                                                                    <div className='redText'> {adperror}</div>

                                                                </div>
                                                            ))}
                                                            <div className='col-12 text-center pt-2'>
                                                                <button className='SaveBtn align-items-center' onClick={handleAddNewADPRows}>
                                                                    <img src='/images/AddMoreIcon.svg' className='img-fluid px-2' />
                                                                    Add More
                                                                </button>
                                                            </div>
                                                        </>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <span className="Emailinvalid" >
                                        {errors.repairCoverageError}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* device login information section */}
                    <div className="greyBox  p-2 my-3 align-items-center" >
                        <div className='row pb-2'>
                            <div className='col-12 font-15'>
                                <b>Device Login Details</b>
                            </div>
                            <div className="col-12 pt-3 ">
                                <div className='col-12 font-13 text-start pb-1 shipdevice-font-style'>
                                    What should we do if we receive devices that are not covered?
                                    <label className='redText'>*</label></div>
                                <div className='col-md-12 font-13 pt-2 ps-3 d-flex align-items-center'>
                                    {uncoveredDevices.map((quote, index) => (
                                        <div className="font-13 px-3 d-flex align-items-center" key={index}>
                                            <input
                                                id={`uncoveredDevices-${quote.id}`}
                                                className="form-check-input me-2 cursor-pointer"
                                                type="radio"
                                                aria-label={quote.id}
                                                name='uncoveredDevices'
                                                checked={repairDetails.uncovereddevice == quote.id}
                                                onChange={(e) => setRepairDetails({ ...repairDetails, uncovereddevice: quote.id })}
                                            />
                                            <label htmlFor={`uncoveredDevices-${quote.id}`} className="form-check-label">
                                                {quote.name}
                                            </label>
                                        </div>
                                    ))}

                                </div>
                                <span className="Emailinvalid" >
                                    {errors.uncovereddeviceError}
                                </span>
                            </div>
                        </div>
                    </div>


                    {/* loaner & asset management section */}
                    <div className="greyBox p-2 my-3 align-items-center" >
                        <div className='row pb-2'>
                            <div className='col-12 font-15'>
                                <b>Loaner & Asset Management Details</b>
                            </div>
                            <div className='col-xl-4 col-12 pt-3'>
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Devices Take-Home<label className='redText'>*</label></div>
                                <div className='d-flex justify-content-between px-1'>
                                    {devicesTakeHome.map((device, index) => (
                                        <div className="col-md-4 font-13 d-flex align-items-center" key={index}>
                                            <input
                                                id={`devicetakehome-${device.id}`}
                                                className="form-check-input mt-1 me-2 cursor-pointer"
                                                type="checkbox"
                                                aria-label={device.id}
                                                checked={loanerAsset?.deviceTakeHome?.includes(String(device.id)) || false}
                                                onChange={(e) => handleDeviceTakeHomeCheckboxChange(e, device.id)}
                                            />
                                            <label htmlFor={`devicetakehome-${device.id}`} className="form-check-label">
                                                {device.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <span className="Emailinvalid" >
                                    {errors.devicesTakeHomeError}
                                </span>
                            </div>
                            <div className="col-xl-8 col-12 pt-3 ">
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>
                                    How many loaner devices do you have? We recommend between 2-4%.<label className='redText'>*</label></div>
                                <input type="number" autoComplete='off' name='countofloaner' className="form-control" required
                                    value={loanerAsset.countofLoanerDevice} onChange={(e) => setLoanerAsset({ ...loanerAsset, countofLoanerDevice: e.target.value })}
                                />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            <div className='col-xl-12 col-12 pt-3'>
                                <div className='col-12 text-start font-13 px-1 pb-1 shipdevice-font-style'>Additional Asset Management System<label className='redText'>*</label></div>
                                <div className='d-flex justify-content-between px-1'>
                                    {additionalAssetManagement.map((assets, index) => (
                                        <div className="font-13 d-flex align-items-center" key={index}>
                                            <input
                                                id={`assets-${assets.id}`}
                                                className="form-check-input mt-1 me-2 cursor-pointer"
                                                type="checkbox"
                                                aria-label={assets.id}
                                                checked={loanerAsset?.additionalAssets?.includes(String(assets.id))}
                                                onChange={(e) => handleAdditionalAssetManagementCheckboxChange(e, assets.id)}
                                            />
                                            <label htmlFor={`assets-${assets.id}`} className="form-check-label">
                                                {assets.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <span className="Emailinvalid" >
                                    {errors.assetManagementError}
                                </span>
                            </div>

                        </div>
                    </div>


                    <div className="col-12 text-center pt-4">
                        <button className="SaveBtn" onClick={handleSaveSchoolProfile}>Save</button>
                        {fromadminorschool == 2 &&
                            <label className="ms-2 cursor-pointer" onClick={(e) => navigate('/schools')}
                            ><u>Cancel</u></label>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
