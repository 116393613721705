import React, { useState } from 'react'
import { HideLoder, ShowLoder } from '../../../JS/Common'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { ApiGetCall } from '../../../JS/Connector'

export default function ExportSchoolProfile() {
    const [loading, setLoading] = useState(false);
    const [SchoolProfileDataCsv, setSchoolProfileDataCsv] = useState([]);
    const [divVisibleForExport, setDivVisibleForExport] = useState(false);
    const [pricingValue, setPricingValue] = useState(null);
    const [routeValue, setRouteValue] = useState(null);
    const headers = [
        { label: "School Name", key: "school_name" },
        { label: "Domain(s)", key: "domain" },
        { label: "Enrollment", key: "Enrollment" },
        { label: "Customer", key: "Customer" },
        { label: "Fedex Shipping Company name", key: "Fedex_Shipping_Company_Name" },
        { label: "Shipping - Phone Number", key: "Shipping_Phone_Number" },
        { label: "Shipping - Street Line 1", key: "Shipping_Street_Line1" },
        { label: "Shipping - Street Line 2", key: "Shipping_Street_Line2" },
        { label: "Shipping - City", key: "Shipping_City" },
        { label: "Shipping - State", key: "Shipping_State" },
        { label: "Shipping - Postal Code", key: "Shipping_Postal_Code" },
        { label: "Billing - First Name", key: "Billing_First_Name" },
        { label: "Billing - Last Name", key: "Billing_Last_Name" },
        { label: "Billing - Phone Number", key: "Billing_Phone_Number" },
        { label: "Billing - Email", key: "Billing_Email" },
        { label: "Billing - Company Name", key: "Billing_Company_Name" },
        { label: "Billing - Street Line 1", key: "Billing_Street_Line1" },
        { label: "Billing - Street Line 2", key: "Billing_Street_Line2" },
        { label: "Billing - City", key: "Billing_City" },
        { label: "Billing - State", key: "Billing_State" },
        { label: "Billing - Postal Code", key: "Billing_Postal_Code" },
        { label: "Account Credit", key: "Account_Credit" },
        { label: "Account Credit Usage", key: "Account_Credit_Usage" },
        { label: "Special Discounts or Pricing", key: "Special_Discounts_or_Pricing" },
        { label: "Contact Email", key: "Contact_Email" },
        { label: "Contact First Name", key: "Contact_First_Name" },
        { label: "Contact Last Name", key: "Contact_Last_Name" },
        { label: "Job Title", key: "Job_Title" },
        { label: "Phone Number & Ext", key: "Phone_Number_Ext" },
        { label: "Relationship Manager", key: "Relationship_Manager" },
        { label: "Location Manager", key: "Location_Manager" },
        { label: "Driver", key: "Driver" },
        { label: "Pick-Up Location", key: "Pick_Up_Location" },
        { label: "Weekly Repairs", key: "Weekly_Repairs" },
        { label: "Devices in District", key: "Devices_in_District" },
        { label: "Quoting Needed?", key: "Quoting_Needed" },
        { label: "Minimum Repair Grade", key: "Minimum_Repair_Grade" },
        { label: "Repair Price Tier", key: "Repair_Price_Tier" },
        { label: "Exceeding Cap Handling", key: "Exceeding_Cap_Handling" },
        { label: "Route Day", key: "Route_Day" },
        { label: "Route Frequency", key: "Route_Frequency" },
        { label: "Cosmetic Damage Repaired?", key: "Cosmetic_Damage_Repaired" },
        { label: "Damage Repaired", key: "Damage_Repaired" },
        { label: "Cosmetic Damage Notes", key: "Cosmetic_Damage_Notes" },
        { label: "Deductible?", key: "Deductible" },
        { label: "Deductible Notes", key: "Deductible_Notes" },
        { label: "Repair Cap", key: "Repair_Cap" },
        { label: "Repair Notes", key: "Repair_Notes" },
        { label: "Repair Coverage", key: "Repair_Coverage" },
        { label: "Uncovered Device Handling", key: "Uncovered_Device_Handling" },
        { label: "Devices Take-Home", key: "Devices_Take_Home" },
        { label: "Loaner Device Percentage", key: "Loaner_Device_Percentage" },
        { label: "Additional Asset Management System", key: "Additional_Asset_Management_System" },
    ];
    const csvReport = {
        filename: "SchoolProfilePricing.csv",
        headers: headers,
        data: SchoolProfileDataCsv,
    };
    async function ExportProfile() {
        setLoading(true);
        await ApiGetCall("/ExportSchoolProfileDetails/" + pricingValue + "&" + routeValue).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setSchoolProfileDataCsv(responseRs);
                setDivVisibleForExport(true);
                setLoading(false);
            }
        });
    }
    function RemoveUserCsv() {
        setSchoolProfileDataCsv([]);
        setDivVisibleForExport(false);
    }
    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}

            <div className='row d-flex align-items-center p-1'>
                <span className='GridTitle'>Export School Profile and Pricing</span>
            </div>
            <div className='col-12'>
                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
            </div>
            <div className="p-2">
                <p className="text-justify" style={{ fontSize: "13px" }}>
                    Would you like to export your school profile and pricing data? To do so, simply click on the 'Export School Profiles and Pricings' button.
                </p>
                <div className='row'>
                    <div className='col-md-3'>
                        <select onChange={(e) => setPricingValue(e.target.value == 0 ? null : e.target.value)}>
                            <option value="0">Filter by Pricing</option>
                            <option value="1">Bronze</option>
                            <option value="2">Silver</option>
                            <option value="3">Gold</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <select onChange={(e) => setRouteValue(e.target.value == 0 ? null : e.target.value)}>
                            <option value="0">Filter by Route Frequency</option>
                            <option value="1">Weekly</option>
                            <option value="2">Bi-Weekly</option>
                            <option value="3">Monthly</option>
                            <option value="4">As-Needed</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3 pb-2 px-3">
                    <div className="col-12 px-0 py-2">
                        <label className='BorderBtnForimportExport' onClick={ExportProfile}>Export School Profiles and Pricings
                            <img src='/images/ExportInventory.svg' className='img-fluid ps-2' />
                        </label>
                    </div>

                </div>
                {divVisibleForExport &&
                    <div className="row pt-2" >
                        <div>Your School Profiles and Pricings CSV file is now ready for download. Please click the button below to initiate the file download.</div>
                        <div className="col-12 mt-1 text-center">
                            <label style={{ fontSize: "12px", color: "#04ADFD" }} id="ExportedStudentFileName">
                                SchoolProfilesPricings.csv
                            </label>
                            <CSVLink {...csvReport} className="WhiteFont"> <button className='SaveBtn ms-4'>Download</button></CSVLink>
                            <FontAwesomeIcon icon={faCircleXmark} className="cursor-pointer ps-3" onClick={RemoveUserCsv} />
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}
