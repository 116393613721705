import { faAddressCard, faBell, faGaugeHigh, faGear, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import { Camelcase, LogInSchoolID, LogInUserID, RemoveAllCookies } from '../../JS/Common';
import { ApiGetCallAuthKey } from '../../JS/Connector';
import Cookies from 'js-cookie';

export default function TopMenu({ setIsWrapperToggled }) {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const path = pathArray[1];
    var instance = process.env.REACT_APP_Instance;
    const { UserData, setUserData } = useContext(UserContext);
    const { emailid: loggedinemailid, UserName: loggedinusername, MenuAccessFlag: SidemenuFlag, copyaccesstype } = UserData;
    const navigate = useNavigate();
    const firstLetter = loggedinusername.split(' ').map(name => name.charAt(0).toUpperCase()).join('');
    const FuncLogout = () => {
        RemoveAllCookies();
        const loadtesting = Cookies.get('isloadtesting');
        window.location.href = loadtesting == '1' ? '/loadtestinglogin' : '/k12logout';
    };
    async function BackToAdmin() {
        const result = await ApiGetCallAuthKey(`/setK12LoginasSchoolLogin/${LogInUserID}&0&2`);
        if (!result) {
            alert("Something went wrong");
            return;
        }
        const responseRs = JSON.parse(result);
        if (responseRs.status == "success") {
            const sugData = responseRs.k12data;
            setUserData({ ...UserData, copyaccesstype: sugData.copy_access_type });
            Cookies.set('CsvUserId', sugData.id, { expires: 1, secure: true, sameSite: 'Strict' });
            Cookies.set('schoolid', 0, { expires: 1, secure: true, sameSite: 'Strict' });
            Cookies.set('Schoolnumber', '', { expires: 1, secure: true, sameSite: 'Strict' });
            window.location.href = '/school-tickets';
        } else {
            alert("Something went wrong");
        }
    }
    const DropdownItem = ({ icon, text, onClick }) => (
        <li>
            <a className="dropdown-item cursor-pointer" onClick={onClick}>
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={icon} className='me-3' />
                    {text}
                </div>
            </a>
        </li>
    );
    return (
        <header className="top-header">
            <nav className="navbar navbar-expand gap-3">
                <div className="mobile-toggle-icon fs-3" onClick={(e) => setIsWrapperToggled(prev => !prev)}>
                    <i className="bi bi-list"></i>
                </div>
                <div className="text-center" style={{ color: '#151821' }}>
                    <h3 className='mb-0'><b>{LogInSchoolID != 0 ? UserData.schoolname : 'K12Tech Admin Panel'}</b></h3>
                </div>
                {window.innerWidth >= 767 && instance && (
                    <label>({instance == 'dev' ? 'Developer Instance' : 'QA Instance'})</label>
                )}
                <div className="top-navbar-right ms-auto">
                    <ul className="navbar-nav align-items-center pe-2">
                        {copyaccesstype && (
                            <li>
                                <div className="dropdown-item cursor-pointer" onClick={BackToAdmin}>
                                    <FontAwesomeIcon icon={faUser} className="me-2" />
                                    <span>Back to Admin Panel</span>
                                </div>
                            </li>
                        )}
                        {path != "create-ticket" && (
                            [8, 9, 10, 11, 13].includes(SidemenuFlag) || (
                                <li className="dropdown-item ms-3">
                                    <div className="d-flex align-items-center">
                                        <img src="/images/TicketIconforTopBar.svg" className="img-fluid cursor-pointer" title="Create Ticket" onClick={() => navigate("/create-ticket", { state: { CreateTicketNext: 1, CreateTicketHeader: 1 } })} />
                                    </div>
                                </li>
                            )
                        )}
                        <Link className="dropdown-item ms-3" to="/faqs">
                            <div className="d-flex align-items-center">
                                <img src='/images/faqicon.svg' className='img-fluid' title='FAQs' />
                            </div>
                        </Link>
                        <li className="nav-item dropdown dropdown-user-setting">
                            <a className="nav-link dropdown-toggle dropdown-toggle-nocaret ps-4" href="#" data-bs-toggle="dropdown">
                                <div className="user-setting d-flex align-items-center">
                                    <div className="profile-icon">
                                        <span>{firstLetter}</span>
                                    </div>
                                </div>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <div className="d-flex align-items-center">
                                            <div className="profile-icon">
                                                <span>{firstLetter}</span>
                                            </div>
                                            <div className="ms-3">
                                                <h5 className="mb-0 dropdown-user-name">{loggedinusername && (Camelcase(loggedinusername))}</h5>
                                                <small className="mb-0 dropdown-user-designation text-secondary" style={{ fontSize: '12px' }}>{loggedinemailid}</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <hr className='my-1' />
                                {[1, 5, 6].includes(SidemenuFlag) && (
                                    <DropdownItem icon={faGaugeHigh} text="Dashboard" onClick={() => navigate('/dashboard')} />
                                )}
                                {SidemenuFlag == 1 && (
                                    <DropdownItem icon={faGear} text="Settings" onClick={() => navigate('/setting')} />
                                )}
                                {SidemenuFlag == 1 && (
                                    <DropdownItem icon={faAddressCard} text="School Profile" onClick={() => navigate('/school-profile')} />
                                )}
                                {[1, 5].includes(SidemenuFlag) && (
                                    <DropdownItem icon={faBell} text="Notifications" onClick={() => navigate('/notifications')} />
                                )}
                                <DropdownItem icon={faPowerOff} text="Sign Out" onClick={FuncLogout} />
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav >
        </header >
    )
}
