import React, { useState, useRef, useEffect } from 'react';
import { LogInSchoolID, LogInUserID } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { ApiPostCall } from '../../JS/Connector';
import { CheckValidation } from '../../Components/Validations';
import { useNavigate } from 'react-router-dom';
import PageHead from '../../Components/PageHead';

const InputGroup = ({ startFrom, values, setValues }) => {
    const firstInputRef = useRef(null);
    useEffect(() => {
        firstInputRef.current && firstInputRef.current.focus();
    }, []);
    const handleInputChange = (index, event) => {
        const newValues = [...values];
        const inputValue = event.target.value.toUpperCase().trim();
        newValues[startFrom + index - 1] = inputValue;
        setValues(newValues);
    };
    const handleKeyPress = (index, event) => {
        if (event.key == "Enter") {
            event.preventDefault();
            const nextInput = document.querySelector(`input[data-index='${startFrom + index + 1}']`);
            nextInput && nextInput.focus();
        }
    };

    return (
        <div className="row">
            {[...Array(20)].map((_, i) => (
                <div key={i} className='col-xl-3 col-md-4 p-2'>
                    <div className='p-3 GridBox boxshaowstyle'>
                        <input
                            className="form-control input-border special-input"
                            type="text"
                            placeholder={`Serial Number ${startFrom + i}`}
                            value={values[startFrom + i - 1] || ''}
                            onChange={(e) => handleInputChange(i, e)}
                            onKeyPress={(e) => handleKeyPress(i, e)}
                            data-index={startFrom + i}
                            ref={i === 0 ? firstInputRef : null}
                        />
                        <div className='fw-600 pt-3' style={{ color: '#030e20ab' }}> Device {startFrom + i}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const MassTicket = () => {
    const initialCount = 20;
    const [groups, setGroups] = useState([1]);
    const [inputValues, setInputValues] = useState(Array(initialCount).fill(''));
    const [errorMsg, setErrorMsg] = useState('');
    const [DevicesFlag, setDevicesFlag] = useState(1);
    const [loading, setLoading] = useState(false);
    const [NewAlerts, setNewAlerts] = useState("");
    const [Section, setSection] = useState(true)
    const [batchname, setBatchname] = useState('')
    const [batchNotes, setBatchNotes] = useState('')
    const [batchDiv, setBatchDiv] = useState(false);
    const [matchingflag, setMatchingflag] = useState(0);
    const [ExistingTicketStatus, setExistingTicketStatus] = useState(2);
    const [ticketsDetails, setTicketsDetails] = useState([]);
    const navigate = useNavigate();
    function addMoreInputs() {
        const lastGroupValues = inputValues.slice(-20);
        if (lastGroupValues.some(value => !value || value.trim() === '')) {
            setErrorMsg('Please complete all fields before adding more');
        } else {
            setErrorMsg('');
            setGroups(prevGroups => [...prevGroups, prevGroups.length + 1]);
            setInputValues(prevValues => [...prevValues, ...Array(20).fill(undefined)]);
        }
    };

    async function saveValues() {
        const SerialNoArray = inputValues.filter(value => value && value.trim() !== '').map(value => ({ SerialNumber: value }));
        if (DevicesFlag == 3) {
            var isFormValid = CheckValidation({ formID: 'CreateBatchDiv' });
            if (!isFormValid) return;
        }
        const raw = JSON.stringify({
            SchoolID: LogInSchoolID,
            UserID: LogInUserID,
            SerialNumbers: SerialNoArray,
            Devicesflag: DevicesFlag,
            Batchname: batchname,
            BatchNotes: batchNotes,
            TicketSelectionFlag: matchingflag == 2 ? 3 : ExistingTicketStatus,
            Details: ticketsDetails
        })
        setLoading(true)
        await ApiPostCall("/MassDeviceAndTicketCreation", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Bulk creation completed successfully"} />);
                    setTimeout(() => {
                        if (DevicesFlag == 1) {
                            navigate('/manage-device')
                        } else if (DevicesFlag == 2) {
                            navigate('/manage-tickets')
                        } else {
                            navigate('/outgoing-batches')
                        }
                        setNewAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    };

    async function DivHideShow() {
        const SerialNoArray = inputValues
            .filter(value => value && value.trim() !== '')
            .map(value => ({ SerialNumber: value }));
        if (SerialNoArray.length === 0) {
            setErrorMsg('Please provide input for at least one field');
            return;
        }
        const raw = JSON.stringify({
            SchoolID: LogInSchoolID,
            UserID: LogInUserID,
            SerialNumbers: SerialNoArray,
            Devicesflag: DevicesFlag,
            Batchname: batchname,
            BatchNotes: batchNotes
        })
        setLoading(true)
        await ApiPostCall("/checkSerialNumsForMassTicket", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                setSection(false)
                setMatchingflag(responseRs.flag);
                setTicketsDetails(responseRs.details)
            }
        });

    }

    function BackToDiv() {
        const SerialNoArray = inputValues
            .filter(value => value && value.trim() !== '')
            .map(value => ({ SerialNumber: value }));
        if (SerialNoArray.length === 0) {
            setErrorMsg('Please provide input for at least one field');
            return;
        }
        setSection(true)
    }

    function getBatchNm() {
        const now = new Date();
        const today = new Date().toISOString().substring(0, 10);
        const time = now.toLocaleTimeString("en-US", { hour12: false }).replace(/:/g, "");
        const batchNamee = `batch_${today}_${time}`;
        setBatchname(batchNamee)
        setBatchDiv(true)
    }
    return (
        <div>
            <div className='row align-items-center px-3'>
                <PageHead heading={"Mass Ticket/Device Creation"}
                    loading={loading} Alerts={NewAlerts} showsearch={false} />
                <div className="GridBox mt-2 px-3 pb-3">
                    <div className="greyBox p-3 mt-3">
                        {Section == true ?
                            <div>
                                <div className="col-12 py-2 text-center">
                                    <label className='text-justify FormLabel'>This feature allows users to effortlessly create multiple devices,
                                        both with and without tickets. Users can simply enter or scan their serial numbers to perform the desired operations. Essentially,
                                        this feature generates active devices in the system if they do not already exist and generates corresponding tickets accordingly.</label>
                                </div>

                                {groups.map((_, index) => (
                                    <InputGroup key={index} startFrom={index * 20 + 1} values={inputValues} setValues={setInputValues} />
                                ))}

                                <div>{errorMsg && <div className='my-2' style={{ color: 'red' }}>{errorMsg}</div>}</div>
                                <div className='col-12 text-center py-4'>
                                    <button className='SaveBtn align-items-center' onClick={addMoreInputs}>   <img src='/images/AddMoreIcon.svg' className='img-fluid px-2' />Add More 20 Device</button>
                                    <button className='SaveBtn ms-3' onClick={DivHideShow}>Save & Next</button>
                                </div>
                            </div>
                            :
                            <div>
                                {matchingflag == 1 && (
                                    <div className='py-3 col-12 '>
                                        <label className='font-13 blueText'>There are open tickets still on some of these devices. Do you want to close the current tickets and open new ones, or leave the current tickets as is?</label>
                                        <div className='ms-5 pt-4 '>
                                            <div>
                                                <input className="radio-btn me-2" type="radio"
                                                    name="checktickets" defaultChecked={ExistingTicketStatus == 1}
                                                    onChange={() => setExistingTicketStatus(1)} id={`existing1`} />
                                                <label htmlFor={`existing1`} className="label font-15" style={{ color: '#4C5258' }}>Close the current tickets and open new ones</label>
                                            </div>
                                            <div>
                                                <input className="radio-btn me-2" type="radio"
                                                    name="checktickets" defaultChecked={ExistingTicketStatus == 2}
                                                    onChange={() => setExistingTicketStatus(2)} id={`existing2`} />
                                                <label htmlFor={`existing2`} className="label font-15" style={{ color: '#4C5258' }}>Leave the current tickets</label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='py-3'>
                                    <label className='fw-600 bulk-creation-text h4'>Which option would you like to choose for mass creation?</label>
                                    <div className='col-md-12 mt-2 py-3'>
                                        <div className="list__item ms-5" key={1}>
                                            <input className="radio-btn pe-3" defaultChecked type="radio" name='devicesflag'
                                                onChange={(e) => { setDevicesFlag(1); setBatchDiv(false) }} itemid={1} id={`opt-${1}`} />
                                            <label htmlFor={`opt-${1}`} className="label font-15" style={{ color: '#4C5258' }}>
                                                Create Devices only
                                            </label>
                                        </div>
                                        <div className="list__item ms-5" key={2}>
                                            <input className="radio-btn" type="radio" name='devicesflag'
                                                onChange={(e) => { setDevicesFlag(2); setBatchDiv(false) }} itemid={2} id={`opt-${2}`} />
                                            <label htmlFor={`opt-${2}`} className="label font-15" style={{ color: '#4C5258' }}>
                                                Create Devices with Tickets
                                            </label>
                                        </div>
                                        <div className="list__item ms-5" key={3}>
                                            <input className="radio-btn" type="radio" name='devicesflag'
                                                onChange={(e) => { setDevicesFlag(3); getBatchNm() }} itemid={3} id={`opt-${3}`} />
                                            <label htmlFor={`opt-${3}`} className="label font-15" style={{ color: '#4C5258' }}>
                                                Create Device with Tickets and Batch Them
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {batchDiv == true && (
                                    <div id="CreateBatchDiv" className="parent">
                                        <div className="col-md-6 offset-md-3 align-items-center py-2">
                                            <div className='col-md-12 text-start fw-600' style={{ color: '#4C5258' }}>Batch Name*</div>
                                            <div className="col-md-12 ps-0">
                                                <input type="text" autoComplete='off' name='batchname' className="form-control" required value={batchname} onChange={(e) => setBatchname(e.target.value)} />
                                                <span className="form-text invalid-feedback" >
                                                    *required
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6 offset-md-3 align-items-center py-2">
                                            <div className='col-md-12 text-start fw-600' style={{ color: '#4C5258' }}> Notes</div>
                                            <div className="col-md-12 ps-0">
                                                <textarea type="text" name='notes' rows={2} className="form-control" value={batchNotes} onChange={(e) => setBatchNotes(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='col-12 text-center py-5'>
                                    <button className='SaveBtn ms-3' onClick={saveValues}>Save</button>
                                    <label className="ms-2 cursor-pointer" onClick={(e) => BackToDiv()}><u>Back</u></label>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MassTicket;
