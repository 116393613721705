import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { SchoolDashBoard } from '../SchoolDashboard';
import { ManageTicket } from '../ManageTicket';
import RepairDetails from '../../Components/RepairDetails';
import { ManageDeviceIndex } from '../ManageDevice';
import { AddUpdate } from '../ManageDevice/addupdate';
import { IndexUsers } from '../ManageUsers';
import { UsersAddUpdate } from '../ManageUsers/addupdate';
import { ManageParts } from '../ManagePart';
import { StaffMemberIndex } from '../StaffMember';
import { DeviceAllocate } from '../DeviceAllocation';
import { ImportExport } from '../ImportExport';
import { SchoolInvoice } from '../SchoolBilling';
import CloseTicketInvoice from '../../Components/CloseTicketInvoice';
import { CreateTicket } from '../CreateTicket';
import { Setting } from '../Setting';
import { FaqIndex } from '../FAQ';
import { ShipDevices } from '../ShipDevices';
import { SchoolReports } from '../SchoolReports';
import { ErrorPage } from '../404Error';
import { TechnologySupportTicket } from '../SupportTicket/Technology';
import { MaintenanceSupportTicket } from '../SupportTicket/Maintenance';
import ManageSoftware from '../ManageSoftware';
import Building from '../Building';
import TicketAssignment from '../TicketAssignments';
import HdticketAssignments from '../Helpdesk/HdticketAssignments';
import Outgoingbatches from '../ShipDevices/Outgoingbatches';
import { Setup } from '../ParentalCoverage/setup';
import Notification from '../Notification';
import AddSetup from '../ParentalCoverage/AddSetup';
import CoveragePurchased from '../ParentalCoverage/CoveragePurchased';
import MassTicket from '../MassTicket';
import TicketHistoryPage from '../Billingandtickets/TicketHistoryPage';
import ShowInvoice from '../SchoolBilling/ShowInvoice';
import ErrorLogsPage from '../ErrorPage/ErrorLogsPage';
import HelpdeskIndex from '../Helpdesk';
import HelpdeskTicket from '../Helpdesk/HelpdeskTicket';
import Helpdeskrequest from '../Helpdesk/Helpdeskrequest';
import UsersGroup from '../UsersGroup';
import DeviceCustomField from '../CustomField/Index';
import InventoryAudit from '../InventoryAudit';
import PreviousAudits from '../InventoryAudit/PreviousAudits';
import History from '../ParentalCoverage/History';
import SchoolProfile from '../SchoolProfile';
export default function Access1() {
    return (
        <Routes>
            <Route exact path="/" element={<SchoolDashBoard />} />
            <Route exact path="/dashboard" element={<SchoolDashBoard />} />
            <Route exact path="/manage-tickets/*" element={<ManageTicket />} />
            <Route exact path="/repair-details/*" element={<RepairDetails />} />
            <Route exact path="/manage-parts" element={<ManageParts />} />
            <Route exact path="/ship-devices" element={<ShipDevices />} />
            <Route exact path="/mass-tickets" element={<MassTicket />} />
            <Route exact path="/outgoing-batches" element={<Outgoingbatches />} />
            <Route path="/manage-device/*" element={<ManageDeviceIndex />} />
            <Route path="/addupdate-device/*" element={<AddUpdate />} />
            <Route path="/users/*" element={<IndexUsers />} />
            <Route path="/addupdate-users/*" element={<UsersAddUpdate />} />
            <Route exact path="/allocate-devices" element={<DeviceAllocate />} />
            <Route exact path="/manage-software" element={<ManageSoftware />} />
            <Route exact path="/import-export" element={<ImportExport />} />
            <Route exact path="/school-invoice" element={<SchoolInvoice />} />
            <Route exact path="/reports" element={<SchoolReports />} />
            <Route exact path="/technology-support-tickets" element={<TechnologySupportTicket />} />
            <Route exact path="/maintenance-support-tickets" element={<MaintenanceSupportTicket />} />
            <Route exact path="/ticket-assignments" element={<TicketAssignment />} />
            <Route path="/staff-members" element={<StaffMemberIndex />} />
            <Route exact path="/building" element={<Building />} />
            <Route exact path="/users-groups" element={<UsersGroup />} />
            <Route exact path="/inventory-audit" element={<InventoryAudit />} />
            <Route exact path="/previous-audits" element={<PreviousAudits />} />
            <Route exact path="/helpdesk-ticket-assignments" element={<HdticketAssignments />} />
            <Route exact path="/setup" element={<Setup />} />
            <Route exact path="/add-setup" element={<AddSetup />} />
            <Route exact path="/plan-history" element={<History />} />
            <Route exact path="*" element={<ErrorPage />} />
            <Route exact path="/CloseTicketInvoice" element={<CloseTicketInvoice />} />
            <Route exact path="/create-ticket" element={<CreateTicket />} />
            <Route exact path="/setting" element={<Setting />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
            <Route exact path="/notifications" element={<Notification />} />
            <Route exact path="/purchases" element={<CoveragePurchased />} />
            <Route exact path="/ticket-history" element={<TicketHistoryPage />} />
            <Route exact path="/generate-invoice" element={<ShowInvoice />} />
            <Route exact path="/error-logs-page" element={<ErrorLogsPage />} />
            <Route exact path="/helpdesk" element={<HelpdeskIndex />} />
            <Route exact path="/add-helpdesk" element={<HelpdeskTicket />} />
            <Route exact path="/helpdesk-requests" element={<Helpdeskrequest />} />
            <Route exact path="/custom-field" element={<DeviceCustomField />} />
            <Route exact path="/school-profile" element={<SchoolProfile />} />
        </Routes>
    )
}
