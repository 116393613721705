export const isValidValue = (value) => {
    if (
        value === undefined ||
        value === null ||
        value === 0 ||
        value === ''
    ) {
        return false;
    }
    return true;
};
export const findParent = (element) => {
    let parentElement = element.parentNode;
    for (let i = 0; i < 12; i++) {
        if (parentElement.classList.contains('parent')) {
            return parentElement;
        } else {
            parentElement = parentElement.parentNode;
        }
    }
    return null;
};
export const CheckValidation = ({ formID }) => {
    let isValid = true;
    const formElement = document.getElementById(formID);
    const inputs = formElement.querySelectorAll('select, textarea, input');

    inputs.forEach((input) => {
        if (input.disabled) {
            return; // Skip validation for disabled inputs
        }
        if (!input.required) {
            return;
        }
        const inputParent = findParent(input);
        if (inputParent && !inputParent.classList.contains('d-none')) {
            const invalidFeedback = input.parentNode.querySelector('.invalid-feedback');
            if (input.tagName == 'SELECT' && input.value === '0') {
                invalidFeedback.style.display = 'block';
                isValid = false;
            } else if (input.type == 'number' && input.value < 0) {
                invalidFeedback.style.display = 'block';
                isValid = false;
            } else if (!isValidValue(input.value)) {
                invalidFeedback.style.display = 'block';
                isValid = false;
            } else {
                invalidFeedback.style.display = '';
            }
        }
    });

    return isValid;
}
