import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { Admindashboard } from '../AdminDashboard/admindashboard'
import { IndexSchools } from '../Schools'
import { SchoolTickets } from '../SchoolTickets'
import RepairDetails from '../../Components/RepairDetails'
import { Technician } from '../Technician'
import { AdminBilling } from '../Billingandtickets/AdminBilling'
import TicketHistoryPage from '../Billingandtickets/TicketHistoryPage'
import { DomainIndex } from '../Domain'
import { CreateTicket } from '../CreateTicket'
import { ReportsIndex } from '../AdminReports'
import { FaqIndex } from '../FAQ'
import { IncomingBatches } from '../IncomingBatch'
import { Locations } from '../Locations'
import { ErrorPage } from '../404Error'
import Notification from '../Notification'
import { MasterInventory } from '../MasterInventory'
import { PricingActivation } from '../ParentalCoverage/PricingActivation'
import SSInfoOnly from '../SSInfoOnly'
import { ImportExport } from '../ImportExport'
import { AddUpdate } from '../ManageDevice/addupdate'
import AddSetup from '../ParentalCoverage/AddSetup';
import MassDownload from '../MassDownload';
import SchoolProfile from '../SchoolProfile';

export default function Access5() {
    return (
        <Routes>
            <Route exact path="/" element={<Admindashboard />} />
            <Route exact path="/dashboard" element={<Admindashboard />} />
            <Route exact path="/schools" element={<IndexSchools />} />
            <Route exact path="/school-tickets/*" element={<SchoolTickets />} />
            <Route exact path="/repair-details/*" element={<RepairDetails />} />
            <Route exact path="/incoming-batches/*" element={<IncomingBatches />} />
            <Route exact path="/admin-invoice" element={<AdminBilling />} />
            <Route exact path="/ticket-history" element={<TicketHistoryPage />} />
            <Route exact path="/create-ticket" element={<CreateTicket />} />
            <Route path="/addupdate-device/*" element={<AddUpdate />} />
            <Route exact path="/technician" element={<Technician />} />
            <Route path="/domain" element={<DomainIndex />} />
            <Route exact path="/locations" element={<Locations />} />
            <Route exact path="*" element={<ErrorPage />} />
            <Route exact path="/master-inventory" element={<MasterInventory />} />
            <Route exact path="/reports" element={<ReportsIndex />} />
            <Route exact path="/faqs" element={<FaqIndex />} />
            <Route exact path="/add-setup" element={<AddSetup />} />
            <Route exact path="/pricing-activation-process" element={<PricingActivation />} />
            <Route exact path="/notifications" element={<Notification />} />
            <Route path="/sprig-statistics" element={<SSInfoOnly />} />
            <Route exact path="/import-export" element={<ImportExport />} />
            <Route exact path="/bulk-downloads" element={<MassDownload />} />
            <Route exact path="/school-profile" element={<SchoolProfile />} />
        </Routes>
    )
}
