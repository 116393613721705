import React, { useEffect, useState } from 'react'
import { ApiGetCall } from '../../JS/Connector';
import { HideLoder, MMDDYYYY, ShowLoder } from '../../JS/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function SchoolProfileInfo({ schoolId }) {
    const [loading, setLoading] = useState(false);
    const [schoolData, setSchoolData] = useState('');
    const [otherRepairs, setOtherRepairs] = useState([]);
    const [visiblePasswords, setVisiblePasswords] = useState({});
    const [groupedDevices, setGroupedDevices] = useState({});
    const deviceTypeMap = {
        1: "Chromebook",
        2: "Windows",
        3: "iPad",
        4: "Macbook"
    };
    useEffect(() => {
        getSchoolProfileInfo();
    }, [])

    async function getSchoolProfileInfo() {
        setLoading(true);
        await ApiGetCall("/schoolProfileInformation/" + schoolId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setSchoolData(responseRs)
                const mappedRepairCoverage = responseRs?.schoolProfile?.mapped_repair_coverage || "";

                const coverageArray = mappedRepairCoverage.split(",").map(name => name.trim());
                const filteredCoverage = coverageArray
                    .filter(name => name == "Parental-Coverage" || name == "Ad-Hoc_Repairs")
                    .map(name => ({ name: name.replace(/[-_]/g, " ") })); // Replace '-' and '_' with space

                setOtherRepairs(filteredCoverage);
                const groupedDevices = responseRs?.schoolProfile?.device_credential_detail?.reduce((acc, device) => {
                    const typeName = deviceTypeMap[device.device_type] || `Unknown Type (${device.device_type})`;

                    if (!acc[typeName]) {
                        acc[typeName] = [];
                    }
                    acc[typeName].push(device);

                    return acc;
                }, {});
                setGroupedDevices(groupedDevices)
            }
        });
    }
    const repairCoverageDetails = schoolData?.schoolProfile?.repair_coverage_details || [];

    const aspWarrantyRepairs = repairCoverageDetails.filter((item) => item.type == 1);
    const adpRepairs = repairCoverageDetails.filter((item) => item.type == 3);

    const togglePassword = (index) => {
        setVisiblePasswords(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    return (
        <div>
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="container-fluid px-0 parent">
                <div className='row'>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel  pe-0'>Quoting Needed? : </div>
                        <div className='col wordwrap'>{schoolData?.schoolProfile?.mapped_needs_quoted || '-'}</div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Minimum Repair Grade : </div>
                        <div className='col wordwrap'>{schoolData?.schoolProfile?.mapped_minimum_repair_grade || '-'}</div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Repair Price Tier : </div>
                        <div className='col wordwrap'>{schoolData?.schoolProfile?.mapped_repair_price_tier || '-'}</div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Repair Cap : </div>
                        <div className='col wordwrap'>{schoolData?.schoolProfile?.repair_cap ? `$${schoolData?.schoolProfile?.repair_cap}` : '-'}</div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Route Day : </div>
                        <div className='col wordwrap'>{schoolData?.schoolProfile?.mapped_route_day || '-'}</div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Cosmetic Damage Notes : </div>
                        <div className='col wordwrap'>{schoolData?.schoolProfile?.cosmetic_damage_notes || '-'}</div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Deductible Notes : </div>
                        <div className='col wordwrap'>{schoolData?.schoolProfile?.deductible_notes || '-'}</div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Repair Notes : </div>
                        <div className='col wordwrap'>{schoolData?.schoolProfile?.repair_notes || '-'}</div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Multi Device Login : </div>
                        <div className='col-12' style={{ overflowX: 'auto', maxWidth: '100%' }}>
                            {groupedDevices ?
                                <table className="table data-table mb-0 font-13" >
                                    <thead className='GridHeader'>
                                        <tr>
                                            <th scope="col" className='fw-600 font-13'></th>
                                            <th scope="col" className='fw-600 font-13'>UserName</th>
                                            <th scope="col" className='fw-600 font-13'>Password</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(groupedDevices).map(([type, devices]) => (
                                            devices.map((device, i) => {
                                                const key = `${type}-${i}`;
                                                return <tr key={i}>
                                                    <td>{type}</td>
                                                    <td>{device.user_name}</td>
                                                    <td>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <input
                                                                type={visiblePasswords[key] ? "text" : "password"}
                                                                value={device.password}
                                                                readOnly
                                                                className="form-control"
                                                                style={{ width: "100px", border: "none", background: "transparent" }}
                                                            />
                                                            <button
                                                                onClick={() => togglePassword(key)}
                                                                style={{ border: "none", background: "none", cursor: "pointer", marginLeft: "5px" }}
                                                            >
                                                                {visiblePasswords[key] ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        ))}
                                    </tbody>
                                </table>
                                :
                                '-'
                            }
                        </div>
                    </div>
                    <div className='col-lg-12 row py-1 mb-5'>
                        <div className='col fw-600 font-13 FormLabel pe-0'>Repair Coverage : </div>
                        <div className='col-12 wordwrap' style={{ overflowX: 'auto', maxWidth: '100%' }}>
                            {repairCoverageDetails?.length == 0 && '-'}
                            {otherRepairs?.length > 0 && (
                                <div className="py-1">
                                    {otherRepairs.map((item, index) => (
                                        <label className='font-13' key={index}>{item.name}</label>
                                    ))}
                                </div>
                            )}
                            {schoolData?.schoolProfile?.mapped_repair_coverage?.includes("ASP_Warranty_Repairs") && aspWarrantyRepairs?.length > 0 && (
                                <div className="coverage-section pb-1">
                                    <table className="table data-table mb-0 font-13" >
                                        <thead className='GridHeader'>
                                            <tr>
                                                <th scope="col" className='fw-600 font-13'></th>
                                                <th scope="col" className='fw-600 font-13'>Device Type</th>
                                                <th scope="col" className='fw-600 font-13'>Coverage Start Date</th>
                                                <th scope="col" className='fw-600 font-13'>Coverage End Date</th>
                                                <th scope="col" className='fw-600 font-13'>Coverage Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aspWarrantyRepairs.map((item) => (
                                                <tr key={item.id} >
                                                    <td >ASP Warranty Repairs</td>
                                                    <td >{item.device_type}</td>
                                                    <td >{MMDDYYYY(item.start_date)}</td>
                                                    <td>{MMDDYYYY(item.end_date)}</td>
                                                    <td >{item.coverage_type || "-"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {schoolData?.schoolProfile?.mapped_repair_coverage?.includes("ADP") && adpRepairs?.length > 0 && (
                                <div className="coverage-section py-1">
                                    <table className="table data-table mb-0 font-13" >
                                        <thead className='GridHeader'>
                                            <tr>
                                                <th scope="col" className='fw-600 font-13'></th>
                                                <th scope="col" className='fw-600 font-13'>Device Type</th>
                                                <th scope="col" className='fw-600 font-13'>Coverage Start Date</th>
                                                <th scope="col" className='fw-600 font-13'>Coverage End Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adpRepairs.map((item) => (
                                                <tr key={item.id} >
                                                    <td>ADP</td>
                                                    <td >{item.device_type}</td>
                                                    <td >{MMDDYYYY(item.start_date)}</td>
                                                    <td>{MMDDYYYY(item.end_date)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}



                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}
